import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle} from "reactstrap"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import api_url from "../../helpers/config.js"
import { fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Datastream_Card extends Component {
  static contextType = DeviceContext

  state = {
    views_output_type_outputs_attribute_sum: [],
    views_input_type_inputs_attribute_sum: []
  }

  getItems(){
    fetchGetData(this.context.device, "views_input_type_inputs_attribute_sum")
    .then(views_input_type_inputs_attribute_sum => {
      if (views_input_type_inputs_attribute_sum.dataExists != 'false') {
        this.setState({ views_input_type_inputs_attribute_sum })
      }
      })
    .catch(err => console.log(err))

    fetchGetData(this.context.device, "views_output_type_outputs_attribute_sum")
    .then(views_output_type_outputs_attribute_sum => {
      if (views_output_type_outputs_attribute_sum.dataExists != 'false') {
        this.setState({ views_output_type_outputs_attribute_sum })
      }
      })
    .catch(err => console.log(err))  
    
  }

  componentDidMount(){
    this.getItems()
  }

  //https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
  arraysEqual(a, b) {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false
  
    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false
    }
    return true
  }

  render() {
    var views_output_type_outputs_attribute_sum_datastream = {}
    var views_input_type_inputs_attribute_sum_view_datastream = {}
    if (this.state.views_input_type_inputs_attribute_sum.dataExists != 'false') {
      views_input_type_inputs_attribute_sum_view_datastream = (this.state.views_input_type_inputs_attribute_sum.filter(item => item.view_id == this.props.view_id)).filter(item => item.attribute_type == 'datastream')
    }
    if (this.state.views_output_type_outputs_attribute_sum.dataExists != 'false') {
      views_output_type_outputs_attribute_sum_datastream = (this.state.views_output_type_outputs_attribute_sum.filter(item => item.view_id == this.props.view_id)).filter(item => item.attribute_type == 'datastream')
    }
    return (
        <Card>
        <CardHeader>
          <div style={{display : 'inline-block'}}>
            <CardTitle tag="h4">Eingehender Datenfluss</CardTitle>
          </div>
        </CardHeader>
        <CardBody>
          <DataTableDeleteAndEdit
            items={views_input_type_inputs_attribute_sum_view_datastream}
            item_type={'input'}
            columns={['Input-Name', 'Rate', 'Einheit']}
            columns_DB={['input_name', 'content', 'type_content']}
          />
        </CardBody>
        <CardHeader>
          <div style={{display : 'inline-block'}}>
            <CardTitle tag="h4">Ausgehender Datenfluss</CardTitle>
          </div>
        </CardHeader>
        <CardBody>
          <DataTableDeleteAndEdit
            items={views_output_type_outputs_attribute_sum_datastream}
            item_type={'output'}
            columns={['Output-Name', 'Rate', 'Einheit']}
            columns_DB={['output_name', 'content', 'type_content']}
          />
        </CardBody>
      </Card>
    )
  }
}
export default Datastream_Card