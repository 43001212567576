import React, { Component } from 'react'
import Rev_DataTableWithSubRev_ConnectionTable from 'components/Extensions/Tables/Revisions/Rev_DataTableWithSubRev_ConnectionTable'
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap"
import { fetchGetDataWithDeleted } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Rev_Input_types_Card extends Component {
  static contextType = DeviceContext

  state = {
    // items = input types
    items: [],
    rev_items: [],
    attribute_types: [],
    input_types_attribute_types: []
  }

  getItems() {
    fetchGetDataWithDeleted(this.context.device, 'input_types')
    .then(items => {
      if (items.dataExists != 'false') {
        this.setState({ items })
      }
    })
    .catch(err => console.log(err))

    fetchGetDataWithDeleted(this.context.device, 'input_types_revisions')
    .then(rev_items => {
      if (rev_items.dataExists != 'false') {
        this.setState({ rev_items })
      }
    })
    .catch(err => console.log(err))

    fetchGetDataWithDeleted(this.context.device, 'attribute_types')
    .then(attribute_types => {
      if (attribute_types.dataExists != 'false') {
        this.setState({ attribute_types })
      }
    })
    .catch(err => console.log(err))

    fetchGetDataWithDeleted(this.context.device, 'input_types_attribute_type')
    .then(input_types_attribute_types => {
      if (input_types_attribute_types.dataExists != 'false') {
        this.setState({ input_types_attribute_types })
      }
    })
    .catch(err => console.log(err))

  }

  refresh = () => {
    this.getItems()
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <div style={{ display: 'inline-block' }}>
            <CardTitle tag="h4">All Input Types managed in ATool</CardTitle>
          </div>
          {" "}
        </CardHeader>
        <CardBody>
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <Rev_DataTableWithSubRev_ConnectionTable
              items={this.state.items}
              rev_items={this.state.rev_items}
              item_type='input_type'
              columns={['ID (debug)', 'Name', 'Deleted?']}
              columns_DB={['input_type_id', 'name', 'deleted']}
              child_items={this.state.attribute_types}
              child_type='attribute_type'
              parent_child_items={this.state.input_types_attribute_types}
              refresh={this.refresh}
            />
          </div>
        </CardBody>
      </Card>
    )
  }
}
export default Rev_Input_types_Card