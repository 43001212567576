/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"

import { faHistory, faSearch, faSignOut } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import translate from "helpers/translations.js"
import { fetchLogout, fetchGetData } from "helpers/api.js"
import { DeviceContext } from "context/DeviceContext.js"


// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal
} from "reactstrap"

import "./AdminNavbar.css"

class AdminNavbar extends React.Component {
  static contextType = DeviceContext

  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
      search_string: '',
      search_items: [],
      filtered_items: []
    }
    this.abortController = new AbortController()
  }

  handleLogOut() {
    console.log("User Log Out")
    fetchLogout()
    .then(response => {
      if (response.error) {
        console.log(response.error)
      } else {
        this.props.history.push('/login')
      }
  })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor) 
    document.addEventListener('click', (event) => {
      if (event.target.matches(".search-bar")) {
        this.setState({ modalSearch: true })
      } else if (!event.target.closest(".modal-search")) {
        this.setState({ modalSearch: false })
      }
    })
    this.getSearchItems()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor)
    // abort outstanding fetch requests to prevent memory leak
    this.abortController.abort()
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      })
    } else {
      this.setState({
        color: "navbar-transparent"
      })
    }
  }

  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      })
    } else {
      this.setState({
        color: "bg-white"
      })
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    })
  }

  // this func will toggle between the revision page and the edit page 
  getLink = () => {
    var current_page_url = window.location.href
    var link = ""
    if (current_page_url.includes("/rev/")) {
      link = "/admin/" + current_page_url.split("/")[5].replace("rev_", "")
    } else {
      link = "/admin/rev/rev_" + current_page_url.split("/")[4]
    }
    this.props.history.push(link)
  }

  handleSearchBar = (event) => {
    this.setState({ search_string: event.target.value })
    if (event.target.value === '') {
      this.setState({ modalSearch: false })
    } else {
      this.setState({ modalSearch: true })
    }
    let filtered_items = {}
    Object.entries(this.state.search_items).forEach(([key, array]) => {
      filtered_items[key] = array.filter(item => {
        // replace so you don't get all inputs etc. when searching for 'Komp' etc.
        let name = item.name.replace('(Komponente: ', '').replace('(Sicht: ', '')
        let included = true
        // check if the search strings are included in the item's name or category
        event.target.value.toLowerCase().split(' ').forEach(subString => {
          if (!name.toLowerCase().includes(subString) && !translate(key).toLowerCase().includes(subString)) included = false
        })
        return included
      })
    })
    this.setState({ filtered_items })
  }

  async getSearchItems() {
    let search_items = {}
    const tables = ['views', 'modules', 'views_component_sum', 'components_input_sum', 'components_output_sum', 'components_internal_sum', 'users']
    const item_types = ['view', 'module', 'component', 'input', 'output', 'internal', 'user']

    
    const items_array = await Promise.all(tables.map(table => {
      return fetchGetData(this.context.device, table)
    }))
    items_array.forEach((items, index) => {
      search_items[item_types[index] + 's'] = []
      if (items && items.dataExists !== 'false') {
        items.forEach(item => {
          let search_item = {}
          if (index <= 2) {
            search_item.link = '/admin/' + item_types[index] + '_' + item[item_types[index] + '_id']
            if (item_types[index] === 'component') search_item.name = item.component_name + ' (Sicht: ' + item.view_name + ')'
            else search_item.name = item.name
          } else if (item_types[index] === 'user') {
            search_item.link = '/admin/' + item_types[index] + '_' + item[item_types[index] + '_id']
            search_item.name = item.first_name + ' ' + item.name
          } else {
            search_item.link = '/admin/component_' + item.component_id
            search_item.name =  item[item_types[index] + '_name'] + ' (Komponente: ' + item.component_name + ')'
          }
          search_items[item_types[index] + 's'].push(search_item)
        })
      }
    })
    
    this.setState({ search_items }) 
  }

  render() {
    let searchModalBody
    let total_length = 0
    Object.values(this.state.filtered_items).forEach(array => total_length += array.length)
    if (total_length === 0) {
      searchModalBody = <div className="search-result-category-wrapper search-result-category"><span>Keine Übereinstimmungen</span></div>
    } else {
      searchModalBody = Object.entries(this.state.filtered_items).map(([key, array]) => {
        if (array.length > 0) {
          return (
            <div className="search-result-category-wrapper" key={key}>
              <div className="search-result-category"><span>{translate(key)}</span></div>
              {array.map((filteredItem, index) => {
                return (
                  <div className="search-result" key={index} onClick={() => this.setState({ modalSearch: false })}>
                    <Link className="search-link" to={filteredItem.link}>{filteredItem.name}</Link>
                  </div>)
              })}
            </div>
          )
            }
      })
    }

    return (
      <>
        <Navbar
          className={classNames("navbar-absolute", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
            {/* <div className="navbar navbar-expand-lg navbar-light bg-light"> */}
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              {/* <NavbarBrand>
                <img
                  src={company_logo}
                  width="200"
                />
                {"   "}
              </NavbarBrand> */}
            </div>
            <button
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navigation"
              data-toggle="collapse"
              id="navigation"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>

            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>
                <Input
                  className="search-bar"
                  ref={input => input && input.focus()}
                  placeholder="SUCHEN"
                  value={this.state.search_string}
                  type="text"
                  onChange={this.handleSearchBar}
                />
                <div id="modal-search" hidden={this.state.search_string === '' || !this.state.modalSearch}>
                  {searchModalBody}
                </div>
                <button className="btn-minimal" id="btn-close-search" onClick={() => this.setState({ search_string: ''})} hidden={this.state.search_string === ''}>
                  x
                </button>

                {/*<Button
                  title="Änderungsverlauf anzeigen"
                  hidden={this.props.user_type_id === 0 || window.location.href.split('_').at(-2).endsWith('user')}
                  onClick={this.getLink}
                >
                  <FontAwesomeIcon icon={faHistory} />
                  <span className="d-lg-none d-md-block">Änderungsverlauf</span>
                </Button>*/}

                <Button title="Logout" onClick={() => this.handleLogOut()}>
                  <FontAwesomeIcon icon={faSignOut} />
                  <span className="d-lg-none d-md-block">Logout</span>
                </Button>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    )
  }
}

export default AdminNavbar
