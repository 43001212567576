import React from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import api_url from "../../../helpers/config.js"
import { fetchEdit } from 'helpers/api.js'
import { DeviceContext } from '../../../context/DeviceContext.js'

class EditFormUser extends React.Component {
  static contextType = DeviceContext

  state = {
    id: 0,
    attributes: {}
  }

  onChange = e => {
    //  only update the changed entry in attributes value
    var new_attributes = {}
    const old_attributes = this.state.attributes
    Object.entries(old_attributes).map(entry => {
      if (entry[0] == e.target.name) {
        new_attributes[e.target.name] = e.target.value
      } else {
        new_attributes[entry[0]] = entry[1]
      }
    })
    this.setState({ attributes: new_attributes })
  }

  submitFormEdit = e => {
    let attribute_values = this.state.attributes
    if (attribute_values.password === '') {
      delete attribute_values.password
    }

    e.preventDefault()
    fetchEdit(this.props.item_type + 's', undefined, this.state.id, attribute_values)
    .then(item => {
      if (Array.isArray(item)) {
        console.log('Edited:')
        console.log(item[0])
        this.props.updateState(item[0])
        this.props.toggle()
      } else {
        console.log('failure')
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const type_id_string = this.props.item_type + '_id'
      var new_attributes = {}
      var type_id = 0

      Object.entries(this.props.item).map(entry => {
        if (entry[0] == type_id_string) {
          type_id = entry[1]
        } else {
          if (this.props.edit_entries_DB.includes(entry[0]))
            new_attributes[entry[0]] = entry[1]
        }
      })
      new_attributes['password'] = ''

      this.setState({ id: type_id })
      this.setState({ attributes: new_attributes })
    }
  }

  render() {
    // use edit_entries to write the headlines (from)
    // use database labels for name and id of input form (form_db)
    // autoFocus on first input field
    const type_id_string = this.props.item_type + '_id'
    const form = this.props.edit_entries.map((column, index) => {
      const form_db = this.props.edit_entries_DB[index]
      if (form_db !== type_id_string) {
        let input_type = (form_db === 'password')? 'password' : 'text'
        return (
          <FormGroup key={index}>
            <Label for={form_db}>{column}</Label>
            <Input 
              type={input_type}
              name={form_db} 
              id={form_db} 
              onChange={this.onChange} 
              value={this.state.attributes[form_db] || ''} 
              autoFocus={index === 0}
            />
          </FormGroup>
        )
      }
    })

    return (
      <Form onSubmit={this.submitFormEdit}>
        {form}
        <Button disabled={!(this.state.attributes.name && this.state.attributes.email)}>Ändern</Button>
      </Form>
    )
  }
}

export default EditFormUser