import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle, Input } from "reactstrap"
import api_url from "../../helpers/config.js"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import ModalAddUser from 'components/Extensions/Modals/ModalAddUser.js'
import { fetchGetUserData, fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'


class User_Management_Card extends Component {
  static contextType = DeviceContext

  state = {
    user_type_id: 0,
    users: [],
    search: ''
  }

  getItems(){
    fetchGetUserData()
    .then(user => {
      if (user.type) this.setState({ user_type_id: user.type })
      if (user.type >= 2) {
        fetchGetData(this.context.device, 'users')
        .then(users => {
        if (users.dataExists != 'false') {
          this.setState({ users })
        }
        })
        .catch(err => console.log(err))
      }
    })
    .catch(err => console.log(err))
  }

  addItemToState = (item) => {
    this.getItems()
  }

  updateState = (item) => {
    const itemIndex = this.state.users.findIndex(data => data.internal_type_id === item.internal_type_id)
    const newArray = [
    // destructure all items from beginning to the indexed item
      ...this.state.users.slice(0, itemIndex),
    // add the updated item to the array
      item,
    // add the rest of the items to the array from the index after the replaced item
      ...this.state.users.slice(itemIndex + 1)
    ]
    this.setState({ items: newArray })
    this.getItems()
  }

  deleteItemFromState = (user_id) => {
    const updatedUsers = this.state.users.filter(user => user.user_id !== user_id)
    this.setState({ users: updatedUsers })
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    if (this.state.user_type_id < 2) {
      return <div></div>
    } else {
      let users = this.state.users.map(user => {
        let new_user = user
        new_user['full_name'] = user['name'] + ', ' + user['first_name']
        return new_user
      })
      return (
        <Card>
          <CardHeader>
            <ModalAddUser
              addItemToState={this.addItemToState}
              item_type='user' 
              columns={['Titel', 'Vorname', 'Nachname', 'Partner', 'Institut', 'Email', 'Passwort', 'Benutzergruppe']}
              columns_DB={['title', 'first_name', 'name', 'partner', 'institute', 'email', 'password', 'user_type_id']}
            />
            <div style={{display: 'inline-block'}}>
              <CardTitle tag="h4">Nutzerverwaltung</CardTitle>
            </div>
            <div style={{display: 'inline-block', paddingLeft: '10px'}}>
              <Input 
              type='text'
              onChange={e => {
                this.setState({ search: e.target.value })
                this.searchTable(e.target.value)
              }}
              placeholder='Suchen'
              value={this.state.search || ''}
              />
            </div>
          </CardHeader>
          <CardBody>
            <div style={{maxHeight: '500px', overflowY: 'auto'}}>
              <DataTableDeleteAndEdit
                items={users}
                updateState={this.updateState} 
                deleteItemFromState={this.deleteItemFromState}
                item_type='user'
                columns={['Titel', 'Name', 'Email', 'Partner', 'Institut', 'Benutzergruppe']}
                columns_DB={['title', 'full_name', 'email', 'partner', 'institute', 'user_type_id']}
                links={{'full_name': {'id': 'user_id', 'type': 'user'}}}
                searchTable={string => this.searchTable = string}
                withEdit={true}
                withDelete={true}
              />
            </div>
          </CardBody>
        </Card>
      )
    }
  }
}
export default User_Management_Card