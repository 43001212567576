import React, { Component } from 'react'
import { Table } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashRestore } from "@fortawesome/free-solid-svg-icons"
import api_url from "../../../../helpers/config.js"
import { DeviceContext } from 'context/DeviceContext'
import { fetchRestoreConnection } from 'helpers/api.js'

// revision table for connections between architecture elements

class Rev_ConnectionTable extends Component {
  static contextType = DeviceContext

  constructor(props) {
    super(props)
  }

  restoreEntry = (item) => {
    var attribute_values = {}
    attribute_values['deleted'] = false
    let confirmChange = window.confirm('Restore ' + this.props.child_type + ' ?')

    if (confirmChange) {
      fetchRestoreConnection(this.context.device, this.props.item_type + 's_' + this.props.child_type, item[this.props.item_type + 's_' + this.props.child_type + '_id'])
      .then(item => {
        if (Array.isArray(item)) {
          console.log('Edited:')
          console.log(item[0])
          this.props.refresh()
        } else {
          console.log('failure')
        }
      })
      .catch(err => console.log(err))

    }
  }

  render() {
    var items = []
    if (Array.isArray(this.props.parent_child_items) && Object.entries(this.props.child_items).length > 0) {
      items = this.props.parent_child_items.map(item => {
        //  search for the entry which defines the id passed by type_id
        var id = 0
        Object.entries(item).map(entry => {
          if (entry[0] == (this.props.item_type + 's_' + this.props.child_type + '_id')) {
            id = entry[1]
          }
        })
        // populate the table
        var entry = []
        var entry_restore = ''
        Object.entries(item).map((entry, index) => {
          entry_restore = <td scope="row" key={index}></td>
          if (entry[0] == 'deleted') {
            if (entry[1]) {
              const restore_btn = <button onClick={() => this.restoreEntry(item)} className="btn-minimal btn-restore"><FontAwesomeIcon icon={faTrashRestore} /></button>
              entry_restore = <td scope="row" key={index}>{restore_btn}</td>
            }
          }
        })

        var entry_name = ''
        Object.entries(item).map((entry, index) => {
          if (entry[0] == this.props.child_type + '_id') {
            var child_entry = this.props.child_items.filter(child_entry => child_entry[this.props.child_type + '_id'] == entry[1])
            if (child_entry[0] != undefined) {
              entry_name = <td scope="row" key={index}>{child_entry[0]['name']}</td>
            }
          }
        })

        if (entry_name != '') {
          entry = [entry_name, entry_restore]
        }

        return (
          <tr key={id}>
            {entry}
          </tr>
        )
      })
    }

    var header = []
    header = this.props.columns.map((column, index) => {
      return (
        <th key={index}>{column}</th>
      )
    })
    return (
      <Table responsive hover>
        <thead>
          <tr>
            {header}
            <th>Restore</th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </Table>
    )
  }
}
export default Rev_ConnectionTable