import React from 'react'

import "./MultiColumnSelectInput.css"
import translate from 'helpers/translations'

class MultiColumnSelectInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      value: null,
      selected: 0
    }
    this.inputRef = React.createRef()
    this.modalRef = React.createRef()
    this.optionsRef = React.createRef()
  }

  // adds and removes all needed EventListeners
  manageEventListeners(eventListenerFunction) {
    // open and close the modal
    eventListenerFunction('click', this.toggleModalAfterClickEvent)
    eventListenerFunction('contextmenu', this.closeModalAfterEvent)
    eventListenerFunction('resize', this.closeModalAfterEvent)

    // focus hovered option
    eventListenerFunction('mouseover', this.focusOptionOnHoverEvent)

    // disable scrolling when the modal is active
    // adapted from https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily
    let supportsPassive = false
    try {
      eventListenerFunction("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; } 
      }))
    } catch(e) {}
    let wheelOpt = supportsPassive ? { passive: false } : false
    eventListenerFunction('wheel', this.scrollModal, wheelOpt)
    eventListenerFunction('touchmove', this.scrollModal, wheelOpt)
    eventListenerFunction('scroll', this.closeModalAfterEvent)
    eventListenerFunction('keydown', this.handleKeyEvent)
  }

  toggleModalAfterClickEvent = (e) => {
    let prev_open = this.state.open
    if (e.target.matches('.select-input') || e.target.parentNode.matches('.select-input') || !e.target.closest('.select-modal')) {
      this.setState({ open: !prev_open })
      if (prev_open) {
        this.inputRef.current?.focus()
        this.manageEventListeners(window.removeEventListener)
      } else {
        this.focusOption('Home')
      }
    } else if (e.target.matches('.select-option') || e.target.parentNode.matches('.select-option')) {
      let option = e.target.closest('.select-option')
      this.setState({ open: false, value: Number(option.id) })
      this.manageEventListeners(window.removeEventListener)
      this.props.onChange(Number(option.id))
      this.inputRef.current?.focus()
    }
  }
  
  closeModalAfterEvent = (e) => {
    if (e.type === 'scroll' || e.type === 'resize' || !e.target.closest('.select-modal')) {
      this.setState({ open: false })
      this.inputRef.current.focus()
      this.manageEventListeners(window.removeEventListener)
    }
  }

  focusOptionOnHoverEvent = (e) => {
    let select_option = e.target.closest('.select-option')
    if (select_option) {
      select_option.focus()
    }
  }

  scrollModal = (e) => {
    let modal = this.modalRef.current
    let deltaY = e.deltaY
    if (this.state.open) {
      if (!e.target.closest('.select-modal') || (modal.scrollTop === 0 && deltaY < 0) || (modal.scrollTop === modal.scrollTopMax && deltaY > 0)) {
        e.preventDefault()
      }
    }
  }

  handleKeyEvent = (e) => {
    let keys = [' ', 'PageUp', 'PageDown', 'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Tab', 'Enter']
    if (this.state.open && keys.includes(e.key)) {
      e.preventDefault()
      if (e.key === 'Tab') this.setState({ open: false })
      else if (e.key !== ' ') this.focusOption(e.key)
    } else if (this.state.open) {
      for (let item of this.optionsRef.current.children) {
        if (item.innerText.toLowerCase().startsWith(e.key)) {
          item.focus()
          break
        }
      }
    }
  }

  focusOption(key) {
    const focused = document.activeElement
    let options = this.optionsRef.current.children
    if (key === 'PageUp' || key === 'Home') {
      options[0].focus()
    } else if (key === 'PageDown' || key === 'End') {
      options[options.length-1].focus()
    } else if (key === 'ArrowDown') {
      if (focused.nextSibling) focused.nextSibling.focus()
      else options[0].focus()
    } else if (key === 'ArrowUp') {
      if (focused.previousSibling) focused.previousSibling.focus()
      else options[options.length-1].focus()
    } else if (key === 'Enter') {
      this.setState({ open: false, value: Number(focused.id) })
      this.props.onChange(Number(focused.id))
      this.inputRef.current.focus()
    }
  }

  openModal = () => {
    if (!this.state.open) {
      this.manageEventListeners(window.addEventListener)
    }
  }

  render() {
    // get currently selected item
    let selected_item = this.props.items.find(item => item[this.props.item_type + '_id'] === this.state.value)
    let selected_values
    if (selected_item) {
      selected_values = this.props.columns.map((column, col_index) => {
        let column_entry = selected_item[column] || '--'
        let padding = col_index === this.props.columns.length-1 ? 0 : 10
        return (
          <div className={'column'} key={col_index} style={{width: this.props.col_widths[col_index], paddingRight: padding}}>{column_entry}</div>
        )
      })
    } else {
      selected_values = <div>{translate(this.props.item_type) + ' auswählen'}</div>
    }
    // create the select options
    let options = this.props.items.map((item, option_index) => {
      let entry = this.props.columns.map((column, col_index) => {
        let column_entry = item[column] || '--'
        let padding = col_index === this.props.columns.length-1 ? 0 : 10
        return (
          <div key={col_index} className={'column'} style={{width: this.props.col_widths[col_index], paddingRight: padding}}>{column_entry}</div>
        )
      })
      return <div key={option_index} id={item[this.props.item_type + '_id']} className={'select-option'} tabIndex={0}>{entry}</div>
    })
    options.unshift(<div key={'none'} className={'select-option'} tabIndex={0}><div className={'column'}>{translate(this.props.item_type) + ' auswählen'}</div></div>)      

    return (
      <>
      <div ref={this.inputRef} className={'select-input'} tabIndex={0} style={this.props.style} onClick={() => this.openModal()}>
        {selected_values}
      </div>
        <div ref={this.modalRef} className={'select-modal'} style={{width: this.inputRef.current ? this.inputRef.current.clientWidth + 2 : 0, top: this.inputRef.current ? this.inputRef.current.getBoundingClientRect().top + this.inputRef.current.clientHeight : 0}} hidden={!this.state.open}>
          <div ref={this.optionsRef} className={'select-options'}>
            {options}
          </div>
        </div>
      </>
    )
  }
}
export default MultiColumnSelectInput