import React from 'react'
import { Message } from 'semantic-ui-react'
import Footer from "../components/Footer/Footer"
import Logo from "../assets/img/i11_Raster_1499x387_RGB.png"
import { Link } from "react-router-dom"
import { Container, Col, Form, FormGroup, Label, Input, Button, Row } from 'reactstrap'
import { fetchLogin } from '../helpers/api'
import './login.css'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      error: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(event) {
    event.preventDefault()

    this.setState({ error: false })


    fetchLogin(this.state.email, this.state.password)
    .then(response => {
      if (response.error === 'email/pw') {
        return this.setState({ error: true })
      } else if (!response.error) {
        // if confirm bring user to management page
        this.props.history.push('/admin/view_management')
      }
    })
    .catch(err => console.log(err))
  }

  handleChange = async (event) => {
    const { target } = event
    const value = target.type === 'checkbox' ? target.checked : target.value
    const { name } = target
    this.setState({
      [name]: value,
    })
  }

  render() {
    const { error } = this.state
    //  build row/col structure with logo and login text boxes
    return (      
        <div className="login">
          <Form className="form" onSubmit={(event) => this.onSubmit(event)}>
            <Row>
              <img src={Logo} className="logo float-right" />
            </Row>
            <Row>
              <h2>AUTOtech.agil Architekturtool V2</h2>
            </Row>
            <Row>
              <Input
                name="email"
                placeholder='Email'
                onChange={(event) => {
                  this.handleChange(event)
                }}
              />
            </Row>
            <Row>
              <Input
                type="password"
                name="password"
                placeholder='Passwort'
                onChange={(event) => {
                  this.handleChange(event)
                }}
              />
            </Row>
            <Row>
              <Button>Login</Button>
            </Row>
              <p hidden={!error} className='error-message'>
                Email oder Passwort ungültig!
              </p>
            {/*<Row>
              <div>
                For Login credentials contact <a className="mailto" href="mailto:voelkel@embedded.rwth-aachen.de">voelkel@embedded.rwth-aachen.de</a>
              </div>
            </Row>
            <Row>
              <Link to="/atool-mas-x64.zip" target="_blank" download><button className="btn btn-default animation-on-hover" type="button">Download MacOS Client</button></Link>
              <Link to="/atool-win32-x64.zip" target="_blank" download><button className="btn btn-default animation-on-hover" type="button">Download Windows Client</button></Link>
            </Row>*/}
          </Form>
          <Footer fluid />
        </div>
    )
  }
}
export default Login
