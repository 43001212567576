/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react";
import License_frontend from "../assets/LICENSE_LIST_frontend.txt"
import License_backend from "../assets/LICENSE_LIST_backend.txt"
import License_client from "../assets/LICENSE_LIST_client.txt"
import { Row, Col , CardBody, Card, CardHeader, CardTitle, Button} from 'reactstrap';

class License extends React.Component {

  render() {
    // renders a grid type view, width is 12
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <CardTitle tag="h4">Licenses</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <div>
                        <a href={License_frontend} download>
                          <button className="btn btn-default btn animation-on-hover" type="button">
                            Frontend licenses
                          </button>
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <a href={License_backend} download>
                          <button className="btn btn-default btn animation-on-hover" type="button">
                            Backend licenses
                        </button>
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <a href={License_client} download>
                          <button className="btn btn-default btn animation-on-hover" type="button">
                            Client licenses
                        </button>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default License;
