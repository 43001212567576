import React, { Component } from 'react'
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import { Card, CardHeader, CardBody, CardTitle} from "reactstrap"
import api_url from "../../helpers/config.js"
import translate from 'helpers/translations.js'
import { DeviceContext } from 'context/DeviceContext'
import { fetchGetData } from 'helpers/api'

class IO_Card extends Component {
  static contextType = DeviceContext

  state = {
    items: []
  }

  getItems() {
    fetchGetData(this.context.device, 'components_' + this.props.item_type + '_sum')
    .then(items => {
      if (items.dataExists !== 'false') {
        this.setState({ items })
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    return (
      <Card>
        <CardHeader>
            <CardTitle tag="h4">{translate(this.props.item_type + 's')}</CardTitle>
        </CardHeader>
        <CardBody>
          <DataTableDeleteAndEdit
            items={this.state.items.filter(item => this.props.components.includes(item.component_id))}
            item_type={this.props.item_type}
            columns={['Komponente', translate(this.props.item_type)]}
            columns_DB={['component_name', this.props.item_type + '_name']}
            links={{'component_name': {'id': 'component_id', 'type': 'component'}}}
            withDelete={false}
            withEdit={false}
          />
        </CardBody>
      </Card>
    )
  }
}

export default IO_Card
