import React from 'react'
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap'
import { fetchEdit } from 'helpers/api.js'
import { DeviceContext } from '../../../context/DeviceContext.js'

class EditForm extends React.Component {
  static contextType = DeviceContext

  state = {
    id: 0,
    attributes: {}
  }

  onChange = e => {
    const { name, type, value, checked } = e.target

    //  only update the changed entry in attributes value
    var new_attributes = {}
    const old_attributes = this.state.attributes
    Object.entries(old_attributes).map(([key, val]) => {
      if (key === name) {
        // if the target is a checkbox, we need to access checked because e.target.value will be a string
        if (type === 'checkbox') new_attributes[key] = checked
        else if (type === 'number') new_attributes[key] = Number(value)
        else new_attributes[key] = value
      } else {
        new_attributes[key] = val
      }
    })
    this.setState({ attributes: new_attributes })
  }

  submitFormEdit = e => { 
    
    e.preventDefault()
    let attributes = {}
    let device_ids = []
    Object.entries(this.state.attributes).forEach(([key, value]) => {
      if (key.split('_')[0] === 'device') {
        if (value) device_ids.push(Number(key.split('_')[1]))
        attributes['device_ids'] = device_ids
      } else {
        attributes[key] = value
      }
    })
    fetchEdit(this.props.item_type + 's', undefined, this.state.id, attributes)
    .then(item => {
      if (Array.isArray(item)) {
        console.log('Edited:')
        console.log(item[0])
        this.props.updateState(item[0])
        this.props.toggle()
      } else {
        console.log('failure')
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const type_id_string = this.props.item_type + '_id'
      var new_attributes = {}
      var type_id = 0

      Object.entries(this.props.item).map(entry => {
        // check if label corresponds to type_id
        if (entry[0] == type_id_string) {
          // set type_id to the value of the entry
          type_id = entry[1]
        } else if (entry[0] === 'full_name') {
          new_attributes['name'] = this.props.item['name']
          new_attributes['first_name'] = this.props.item['first_name']
        } else if (entry[0] === 'devices') {
          const current_devices = this.props.current_devices.map(device => device.device_id)
          this.props.devices.forEach(device => new_attributes['device_' + device.device_id] = current_devices.includes(device.device_id))
        // check if label is in edit_entries_DB
        } else if (this.props.edit_entries_DB.includes(entry[0])) {
          // set the value of the entry in new_attributes
          new_attributes[entry[0]] = entry[1]
        }
      })

      this.setState({ id: type_id })
      this.setState({ attributes: new_attributes })
    }
  }

  render() {
    // use edit_entries to write the headlines (from)
    // use database labels for name and id of input form (form_db)
    // autoFocus on first input field
    const { item_type, edit_entries, edit_entries_DB } = this.props
    const type_id_string = `${item_type}_id`

    const formGroupElements = edit_entries.map((column, index) => {
      const form_db = edit_entries_DB[index]
      const value = this.state.attributes[form_db] ?? ''
      const autoFocus = index === 0

      if(form_db === type_id_string) return null;

      if(typeof value === "boolean") {
        // represent boolean as checkbox
        return (
          <FormGroup key={index} row>
            <Label for={form_db} sm={5}>{column}</Label>
            <Col sm={2}>
              <Input
                type="checkbox"
                name={form_db} 
                id={form_db} 
                onChange={this.onChange} 
                value={value}
                autoFocus={ autoFocus }
                checked={value}
              />
            </Col>
          </FormGroup>
        )
      } else if (form_db === 'user_type_id') {
        return (
          <FormGroup key={index}>
            <Label for={form_db}>{column}</Label>
            <Input
              type="select"
              name={form_db}
              id={form_db}
              onChange={this.onChange}
              value={this.state.attributes[form_db]}>
              <option value='0'>Gast</option>
              <option value='1'>Entwickler</option>
              <option value='2'>Administrator</option>
            </Input>
          </FormGroup>
        )
      } else if (form_db === 'full_name') {
        return (
          <FormGroup key={index}>
            <FormGroup>
              <Label for={'first_name'}>Vorname</Label>
              <Input 
                type="text" 
                name={'first_name'} 
                id={'first_name'} 
                onChange={this.onChange} 
                value={this.state.attributes['first_name'] || ''} 
              />
            </FormGroup>
            <FormGroup>
              <Label for={'name'}>Name</Label>
              <Input 
                type="text" 
                name={'name'} 
                id={'name'} 
                onChange={this.onChange} 
                value={this.state.attributes['name'] || ''} 
              />
            </FormGroup>
          </FormGroup>
        )
      } else if (form_db === 'devices') {
        let checkboxes = this.props.devices.map(device => {
          const checked = this.props.current_devices ? this.props.current_devices.map(cur => cur.device_id).includes(device.device_id) : false
          return (
            <FormGroup key={device.device_id} style={{marginLeft: '20px'}}>
              <Input
                type="checkbox"
                name={'device_' + device.device_id} 
                id={'device_' + device.device_id} 
                onChange={this.onChange} 
                value={value[device.device_id]}
                defaultChecked={checked}
              />
              <Label for={'device_' + device.device_id} style={{fontWeight: 'normal', margin: '0'}}>{device.name}</Label>
            </FormGroup>
          )
        })
        return (
          <FormGroup key={index}>
            <Label>Geräte</Label>
            {checkboxes}
          </FormGroup>
        )
      } else {
        return (
          <FormGroup key={index}>
            <Label for={form_db}>{column}</Label>
            <Input 
              type="text" 
              name={form_db} 
              id={form_db} 
              onChange={this.onChange} 
              value={this.state.attributes[form_db] || ''} 
            />
          </FormGroup>
        )
      }
    })
    
    return (
      <Form onSubmit={this.submitFormEdit}>
        {formGroupElements}
        <Button disabled={!this.state.attributes.name}>Ändern</Button>
      </Form>
    )
  }
}

export default EditForm