import React, { Component } from 'react'
import { Card, CardBody } from "reactstrap"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit'
import { fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Modules_Card extends Component {
  static contextType = DeviceContext

  state = {
    items: []
  }

  getItems() {
    fetchGetData(this.context.device, 'modules')
    .then(items => {
      if (items.dataExists !== 'false') {
        this.setState({ items: items.filter(item => item.test_architecture === this.props.test_architecture) })
      }
    })
    .catch(err => console.log(err))
  }

  addItemToState = (item) => {
    this.getItems()
    this.props.update_routes(window)
  }

  updateState = (item) => {
    const itemIndex = this.state.items.findIndex(data => data.module_id === item.module_id)
    const newArray = [
    // destructure all items from beginning to the indexed item
      ...this.state.items.slice(0, itemIndex),
    // add the updated item to the array
      item,
    // add the rest of the items to the array from the index after the replaced item
      ...this.state.items.slice(itemIndex + 1)
    ]
    this.setState({ items: newArray })
  }

  deleteItemFromState = (module_id) => {
    const updatedItems = this.state.items.filter(item => item.module_id !== module_id)
    this.setState({ items: updatedItems })
    this.props.update_routes(window)
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    let columns = ['Name', 'Beschreibung']
    let columns_DB = ['name', 'description']
    if (this.props.test_architecture) {
      columns = ['Name', 'Beschreibung', 'Kategorisierung', 'Bewertungsmetrik']
      columns_DB = ['name', 'description', 'categorization', 'metric']
    }
    return (
      <Card>
        <CardBody>
          <DataTableDeleteAndEdit
            title={this.props.test_architecture ? 'Testarchitekturen' : 'Module'}
            items={this.state.items} 
            addItemToState={this.addItemToState}
            updateState={this.updateState} 
            deleteItemFromState={this.deleteItemFromState}
            item_type='module'
            columns={columns}
            columns_DB={columns_DB}
            links={{'name': {'id': 'module_id', 'type': 'module'}}}
            flags={this.props.test_architecture ? ['test_architecture'] : []}
            withEdit={!(this.props.user_type_id === 0)}
            withDelete={!(this.props.user_type_id === 0)}
          />
        </CardBody>
      </Card>
    )
  }
}
export default Modules_Card