import api_url from './config'
import history from './history'

/**
 * Create entry in database
 * @param {*} device The device to add to
 * @param {*} tableName The name of the table to add to
 * @param {*} attribute_values The values to add to the table
 * @returns 
 */
export async function fetchAddNew(device, tableName, attribute_values){
  try{
    const response = await fetch(api_url + 'system_pattern_add_new', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        device: device,
        table: tableName,
        attribute_values: attribute_values
      })
    })
    if (response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    }else{
      const res = await response.json()
      return res
    }
  } catch(err){
    console.log(err)
  }
}

export async function fetchAddInternalConnection(device, parent_type, parent_id, child_type, child_id, component_id){
  try{
    const response = await fetch(api_url + 'system_pattern_add_internal_connection', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        parent_type: parent_type,
        parent_id: parent_id,
        child_type: child_type,
        child_id: child_id,
        component_id: component_id
      })
    })
    if (response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    }else{
      const res = await response.json()
      return res
    }
  } catch(err){
    console.log(err)
  }
}

export async function fetchAddTo(device, parent_id, parent, child_id, child, table){
  try{
    const response = await fetch(api_url + 'system_pattern_add_to', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        parent: parent,
        child: child,
        parent_id: parent_id,
        child_id: child_id,
        table: table
      })
    })
    if (response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    }else{
      const res = await response.json()
      return res
    }
  } catch(err){
    console.log(err)
  }
}

// TODO: potentially undefined id_string
// TODO: remove device
export async function fetchEdit(table, id_string, id, attribute_values){
  try{
    const response = await fetch(api_url + 'system_pattern_edit', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        table: table,
        id_string: id_string,
        id: id,
        attribute_values: attribute_values
      })
    })
    if (response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    }else{
      const res = await response.json()
      return res
    }
  }catch(err){
    console.log(err)
  }
}

/**
 * Fetch data from a specified table
 * @param {*} device 
 * @param {*} table 
 * @returns 
 */
export async function fetchGetData(device, table, abortSignal=null, history=null){
  try{
    const response = await fetch(api_url + 'system_pattern_get_data', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        device: device,
        table: table
      }),
      signal: abortSignal
    })
    if(response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    }else{
      const res = await response.json()
      return res
    }
  }catch(err){
    console.log(err)
  }
}

export async function fetchRemove(table, id){
  try{
    const response = await fetch(api_url + 'system_pattern_remove', {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        table: table,
        id: id,
      })
    })
    if (response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    }else{
      const res = await response.json()
      return res
    }
  }catch(err){
    console.log(err)
  }
}

export async function fetchRemoveFrom(table, id){
  try{
    const response = await fetch(api_url + 'system_pattern_remove_from', {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        table: table,
        id: id,
      })
    })
    if (response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    }else{
      const res = await response.json()
      return res
    }
  }catch(err){
    console.log(err)
  }
}

export async function fetchRestoreConnection(device, table, id){
  try{
    const response = await fetch(api_url + 'system_pattern_restore_connection', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        table: table,
        id: id
      })
    })
    if (response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    } else {
      const res = await response.json()
      return res
    }
  }catch(err){
    console.log(err)
  }
}

export async function fetchRemoveRevision(table, id){
  try{
    const response = await fetch(api_url + 'system_pattern_remove_revision', {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        table: table + 's_revision',
        id: id
      })
    })
    if (response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    }else{
      const res = await response.json()
      return res
    }
  }catch(err){
    console.log(err)
  }
}

export async function fetchGetUserData(history=null){
  try{
    const response = await fetch(api_url + 'system_pattern_get_user_data', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    if(response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    } else {
      const res = await response.json()
      return res
    }
  } catch(err){
    console.log(err)
  }
}

export async function fetchLogin(email, password){
  try{
    const response = await fetch(api_url + 'system_pattern_login', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        email: email,
        password: password
      })
    })
    if (response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    } else {
      const res = await response.json()
      return res
    }
  }catch(err){
    console.log(err)
  }
}

export async function fetchLogout(){
  try{
    const response = await fetch(api_url + 'system_pattern_logout', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    if (response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    } else {
      const res = await response.json()
      return res
    }
  }catch(err){
    console.log(err)
  }
}

export async function fetchGetDataWithDeleted(device, table){
  try{
    const response = await fetch(api_url + 'system_pattern_get_data_with_deleted', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        device: device,
        table: table
      })
    })
    if (response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    } else {
      const res = await response.json()
      return res
    }
  }catch(err){
    console.log(err)
  }
}

export async function fetchGetGitNamespace(){
  try{
    const response = await fetch(api_url + 'system_pattern_get_git_namespace', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    if (response.status === 401){
      // redirect when user is not authenticated (see isAuthenticated in server.ts for more info)
      if(history) history.push('/login')
    } else {
      const res = await response.json()
      return res
    }
  }catch(err){
    console.log(err)
  }
}