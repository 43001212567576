import React from "react"

import Header_Card from "./Header_Card"
import Components_Card from "./Components_Card"

class Degradation_Rule extends React.Component {

  render() {
    return (
      <>
        <div className="content">
          <Header_Card degradation_rule={this.props.degradation_rule} user_type_id={this.props.user_type_id} />
          <Components_Card degradation_rule_id={this.props.degradation_rule.degradation_rule_id} user_type_id={this.props.user_type_id} />
        </div>
      </>
    )
  }
}

export default Degradation_Rule
