import React, { Component } from 'react'
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit'
import { Card, CardBody } from "reactstrap"
import { fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'
import translate from 'helpers/translations'

class IO_types_Card extends Component {
  static contextType = DeviceContext

  state = {
    views_io_type: [],
    io_types:[]
  }

  getItems() {
    fetchGetData(this.context.device, 'views_' + this.props.item_type + '_sum')
    .then(views_io_type => {
      if (views_io_type.dataExists != 'false') {
        this.setState({ views_io_type })
      }
      })
    .catch(err => console.log(err))
    
    fetchGetData(this.context.device, this.props.item_type + 's')
    .then(io_types => {
      if (io_types.dataExists != 'false') {
        this.setState({ io_types })
      }
      })
    .catch(err => console.log(err))
  }

  addItemToState = (view_io_type) => {
    const io_type_to_add = this.state.io_types.find(io_type => io_type[this.props.item_type + '_id'] === view_io_type[this.props.item_type + '_id'])
    view_io_type[this.props.item_type + '_name'] = io_type_to_add['name']
    this.setState({ views_io_type: [view_io_type].concat(this.state.views_io_type) })
  }

  deleteItemFromState = (id) => {
    const updated_items = this.state.views_io_type.filter(item => item['views_' + this.props.item_type + '_id'] !== id)
    this.setState({ views_io_type: updated_items })
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    let views_io_type = this.state.views_io_type.map(item => {
      item['name'] = item[this.props.item_type + '_name']
      return item
    })
    views_io_type = views_io_type.filter(item => item.view_id === this.props.view_id)
    const view_io_type_ids = views_io_type.map(item => item[this.props.item_type + '_id'])
    let io_types_to_connect = this.state.io_types.filter(item => !view_io_type_ids.includes(item[this.props.item_type + '_id']))
    return (
      <Card>
        <CardBody>
          <DataTableDeleteAndEdit
            title={translate(this.props.item_type + 's')}
            items={views_io_type}
            addItemToState={this.addItemToState}
            deleteChildItemFromState={this.deleteItemFromState}
            columns={['Name']}
            columns_DB={['name']}
            select={{'name': {items: io_types_to_connect, col_width: 1, columns: ['name']}}}
            item_type={'views_' + this.props.item_type}
            parent_type={'view'}
            parent_id={this.props.view_id}
            child_type={this.props.item_type}
            withDelete={!(this.props.user_type_id === 0)}
            addTo={true}
            deleteFrom={true}
          />
        </CardBody>
      </Card>
    )
  }
}
export default IO_types_Card