import React, { Component } from 'react'
import { Card, CardBody } from "reactstrap"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import { fetchGetData, fetchGetUserData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Components_Card extends Component {
  static contextType = DeviceContext

  state = {
    views_component: [],
    components:[],
    user: {}
  }

  getItems() {
    fetchGetData(this.context.device, 'views_component')
    .then(views_component => {
      if (views_component.dataExists != 'false') {
        this.setState({ views_component })
      }
      })
    .catch(err => console.log(err))
    
    fetchGetData({}, 'components')
    .then(components => {
      if (components.dataExists != 'false') {
        this.setState({ components })
      }
      })
    .catch(err => console.log(err))
    
    fetchGetUserData()
    .then(user => {
      this.setState({ user })
    })
    .catch(err => console.log(err))
  }

  addItemToState = (view_component) => {
    this.props.update_routes(window)
    this.getItems()
  }

  updateState = (view_component) => {
    const itemIndex = this.state.views_component.findIndex(data => data.view_id === view_component.view_id)
    const newArray = [
    // destructure all items from beginning to the indexed item
      ...this.state.views_component.slice(0, itemIndex),
    // add the updated item to the array
    view_component,
    // add the rest of the items to the array from the index after the replaced item
      ...this.state.views_component.slice(itemIndex + 1)
    ]
    this.setState({ views_component: newArray })
  }

  deleteItemFromState = (component_id) => {
    const updatedItems = this.state.views_component.filter(item => item.component_id !== component_id)
    this.setState({ views_component: updatedItems })
    this.getItems()
    this.props.update_routes(window)
  }

  componentDidMount(){
    this.getItems()
  }

  render() {
    let templates = this.state.components.filter(component => component.is_template)
    templates.forEach(template => template['template_name'] = template['name'])

    const view_component_ids = this.state.views_component.filter(view => view['view_id'] === this.props.view_id).map(view => view['component_id'])
    let components = []
    this.state.components.forEach(component => {
      if (component.devices.map(device => device['device_id']).includes(this.context.device['device_id']) && view_component_ids.includes(component['component_id'])) {
        if (component['template_id']) {
          component['template_name'] = templates.find(template => template['component_id'] === component['template_id'])['template_name']
        }
        components.push(component)
      }
    })

    return (
      <Card>
        <CardBody>
          <DataTableDeleteAndEdit
            title={'Komponenten'}
            items={components}
            addItemToState={this.addItemToState}
            updateState={this.updateState} 
            deleteItemFromState={this.deleteItemFromState}
            item_type={'component'}
            parent_type='view'
            parent_id={this.props.view_id}
            child_type={'component'}
            user={this.state.user}
            columns={['Name', 'Template (optional)', 'Beschreibung']}
            columns_DB={['name', 'template_name', 'description']}
            select={{'template_name': {items: templates, col_width: 1, columns: ['template_name']}}}
            no_edit={['template_name']}
            links={{'name': {'id': 'component_id', 'type': 'component'}, 'template_name': {'id': 'template_id', 'type': 'component'}}}
            withEdit={!(this.props.user_type_id === 0)}
            withDelete={!(this.props.user_type_id === 0)}
            newAndAddTo={true}
          />
        </CardBody>
      </Card>
    )
  }
}
export default Components_Card