/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Switch, Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import { DeviceContextProvider } from "context/DeviceContext";

import AdminLayout from "layouts/Admin.js";

import Login from "./login/login";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "assets/css/shop-item.css"
import "assets/css/custom.css";

import history from "helpers/history"

ReactDOM.render(
  <Router history={history}>
    <DeviceContextProvider>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Redirect from="/" to="/admin/view_management" />
        {/* <Route path="/" element={<Navigate to="/admin/view_management"/>}/> */}
      </Switch>
    </DeviceContextProvider>
  </Router>,
  document.getElementById("root")
);
