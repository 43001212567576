import React, { Component } from 'react'
import { Table, Row, Col } from 'reactstrap'
import api_url from "../../../../helpers/config.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashRestore, faWindowRestore } from "@fortawesome/free-solid-svg-icons"
import Rev_ModalConnectionTable from '../../Modals/Revisions/Rev_ModalConnectionTable'
import { fetchRemoveFrom } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'
import { fetchEdit } from 'helpers/api.js'
import { fetchRemoveRevision } from 'helpers/api.js'

// revision table for data content and a revisison connection sub table displayed in a modal

class Rev_DataTableWithSubRev_ConnectionTable extends Component {
  static contextType = DeviceContext

  constructor(props) {
    super(props)
    this.state = {
      item_type_id_string: this.props.item_type + '_id'
    }
  }

  getRevisison(entry, id) {
    if (Object.entries(this.props.rev_items).length > 0 && this.props.rev_items.dataExists != 'false') {
      const revisions = this.props.rev_items.filter(rev_item => rev_item[this.state.item_type_id_string] == id)

      if (Object.entries(revisions).length > 0 && this.props.columns_DB.includes(entry[0])) {
        const revision = revisions.map(revision => {
          if (revision[entry[0]] != entry[1]) {
            // return an array [revision_content, timestamp, [content, restored, deleted], revision_id]
            if (revision[entry[0]] == false) {
              return ["", revision['created_at'], 'deleted', revision[this.props.item_type + '_revision_id']]
            } else if (revisions[entry[0]] == true) {
              return ["", revision['created_at'], 'restored', revision[this.props.item_type + '_revision_id']]
            } else {
              return [revision[entry[0]], revision['created_at'], 'content', revision[this.props.item_type + '_revision_id']]
            }
          }
        })
        var filtered = revision.filter(function (el) {
          return el != null
        })
        return filtered[0]
      }
    }
  }

  changeToRevisionState = (attribute, revisisonInfoArray, item) => {
    const revision_id = revisisonInfoArray[3]
    const revision_attribute_content = revisisonInfoArray[0]
    const revision_type = revisisonInfoArray[2]

    var changeAttributes = {}
    Object.entries(item).map(entry => {
      if (entry[0] != this.state.item_type_id_string && entry[0] != 'created_at' && entry[0] != 'updated_at') {
        changeAttributes[entry[0]] = entry[1]
      }
    })
    changeAttributes[attribute[0]] = revision_attribute_content


    if (revision_type == 'content') {
      let confirmChange = window.confirm('Change to state?')
      if (confirmChange) {
        fetchEdit(this.props.item_type + 's', item[this.state.item_type_id_string], changeAttributes)
        .then(item => {
          if (Array.isArray(item)) {
            console.log('Edited:')
            console.log(item[0])
            fetchRemoveRevision(this.props.item_type + 's_revisions', revision_id)
            .then(item => {
              console.log('removed')
              console.log(item)
              this.props.refresh()
            })
            .catch(err => console.log(err))
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
      }
    } else if (revision_type == 'deleted') {
      let confirmChange = window.confirm('Restore Item?')
      var changeAttributes = {}
      changeAttributes['deleted'] = false
      if (confirmChange) {
        fetchEdit(this.props.item_type + 's', undefined, item[this.state.item_type_id_string], changeAttributes)
        .then(item => {
          if (Array.isArray(item)) {
            console.log('Edited:')
            console.log(item[0])
            fetchRemoveRevision(this.props.item_type + 's_revisions', revision_id)
            .then(item => {
              console.log('removed')
              console.log(item)
              this.props.refresh()
            })
            .catch(err => console.log(err))
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
      }
    }

  }

  render() {
    var items = []

    if (Array.isArray(this.props.items)) {

      items = this.props.items.map(item => {
        //  search for the entry which defines the id passed by type_id
        var id = 0
        Object.entries(item).map(entry => {
          if (entry[0] == this.props.item_type + '_id') {
            id = entry[1]
          }
        })

        var entry = []
        var check_rev = false
        Object.entries(item).map((entry, index) => {
          if (entry[0] == 'updated_at' && entry[1] !== null) {
            check_rev = true
          }
        })

        var entry = []
        entry = Object.entries(item).map((entry, index) => {
          var revision_entries = ""
          const revisisonInfoArray = this.getRevisison(entry, id)
          if (Array.isArray(revisisonInfoArray)) {
            //[revision_content, timestamp, [content, restored, deleted], revision_id]
            if (revisisonInfoArray[2] == 'deleted') {
              const resotre_btn = <button onClick={() => this.changeToRevisionState(entry, revisisonInfoArray, item)} className="btn-minimal btn-restore" data-toggle="tooltip" data-placement="top" title="Restore this entry">
                <FontAwesomeIcon icon={faTrashRestore} />
              </button>
              revision_entries =
                <p key={index + '_button'} className="text-info">Has been removed at
                {" "}
                  {revisisonInfoArray[1]}
                  {" "}
                  {resotre_btn}
                </p>
            } else if (revisisonInfoArray[2] == 'restored') {
              revision_entries =
                <p key={index + '_button'} className="text-info">Has been restored at
                {" "}
                  {revisisonInfoArray[1]}
                </p>
            } else if (revisisonInfoArray[2] == 'content') {
              const win_restore_btn = <button onClick={() => this.changeToRevisionState(entry, revisisonInfoArray, item)} className="btn-minimal btn-window-restore" data-toggle="tooltip" data-placement="top" title="Restore this entry">
                <FontAwesomeIcon icon={faWindowRestore} />
              </button>
              revision_entries =
                <p key={index + '_button'} className="text-info">{revisisonInfoArray[0]} Has been set at
                {" "}
                  {revisisonInfoArray[1]}
                  {" "}
                  {win_restore_btn}
                </p>
            }
          }

          if (this.props.columns_DB.includes(entry[0]) && check_rev == true) {
            return (
              <td scope="row" key={index}>{entry[1]}{revision_entries}</td>
            )
          } else if (this.props.columns_DB.includes(entry[0])) {
            return (
              <td scope="row" key={index}>{entry[1]}</td>
            )
          }
        })

        return (
          <tr key={id}>
            {entry}
            <td scope="row">
              <Rev_ModalConnectionTable
                item_id={id}
                items={this.props.items}
                rev_items={this.props.rev_items}
                item_type={this.props.item_type}
                child_items={this.props.child_items}
                child_type={this.props.child_type}
                parent_child_items={this.props.parent_child_items}
                refresh={this.props.refresh}
              />
            </td>
          </tr>
        )
      })
    }

    var header = []
    header = this.props.columns.map((column, index) => {
      return (
        <th key={index}>{column}</th>
      )
    })
    return (
      <Table hover striped className="dataTableDeleteAndEdit">
        <thead className="text-primary">
          <tr>
            {header}
            <th key={'subitems'}>Attributes</th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </Table>
    )
  }
}
export default Rev_DataTableWithSubRev_ConnectionTable