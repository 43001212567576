import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle} from "reactstrap"
import api_url from "../../helpers/config.js"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import { fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Components_Card extends Component {
  static contextType = DeviceContext

  state = {
    components_user_filtered: [],
    components:[],
    component_ids: []
  }

  getItems(){
    fetchGetData(this.context.device, "inputs_output_sum_user")
    .then(components_user => {
      if (components_user.dataExists != 'false') {
        console.log(components_user)
        var added_component_ids = []
        var components_user_filtered = []
        components_user.map(component_user => {
          if (!added_component_ids.includes(component_user.component_id)){
            added_component_ids.push(component_user.component_id)
            components_user_filtered = components_user_filtered.concat(component_user)
          }
        })
        console.log(components_user_filtered)
        this.setState({ components_user_filtered })
      }
      })
    .catch(err => console.log(err))

    fetchGetData(this.context.device, "components_user")
    .then(components => {
      var component_ids = []
      if (components.dataExists != 'false') {
        components.filter(component => (component.user_id == this.props.user_id))
        components.map(component => {component_ids.push(component.component_id)})
        this.setState({ component_ids })
      }
      })
    .catch(err => console.log(err))  
  
  }

  componentDidMount(){
    this.getItems()
  }

  render() {
    console.log(this.state.component_ids)
    console.log(this.state.components_user_filtered)
    var components_user_filtered_local = []
    this.state.components_user_filtered.map(component_user_filtered => {
      if(this.state.component_ids.includes(component_user_filtered.component_id)){
        components_user_filtered_local.push(component_user_filtered)
      }
    })
    console.log(components_user_filtered_local)
    return (
        <Card>
        <CardHeader>
          <div style={{display : 'inline-block'}}>
            <CardTitle tag="h4">Your Stakeholder Components</CardTitle>
          </div>
        </CardHeader>
        <CardBody>
            <DataTableDeleteAndEdit
              items={components_user_filtered_local}
              item_type={'components_user'}
              columns={['Name']}
              columns_DB={['component_name']}
              links={{'component_name': {'id': 'component_id', 'type': 'component'}, 'view_name': {'id': 'view_id', 'type': 'view'}}}
            />
        </CardBody>
      </Card>
    )
  }
}
export default Components_Card