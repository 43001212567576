import React, { Component } from 'react'
import { CardBody, Card, CardHeader, CardTitle, Button} from 'reactstrap'
import api_url from "../../helpers/config.js"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBackward, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faSave } from "@fortawesome/free-solid-svg-icons"
import Cytoscape from 'cytoscape'
import CytoscapeComponent from 'react-cytoscapejs'
import { fetchGetData, fetchEdit } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'
import dagre from 'cytoscape-dagre'
Cytoscape.use(dagre)

class Export_Card extends Component {
  static contextType = DeviceContext

  state = {
    view_id: 0,
    views_component: [],
    components: [],
    inputs_output_sum: [],
    ready: false,
    default_layout: false
  }

  async getItems() {
    try {
      let inputs_output_sum = await fetchGetData(this.context.device, "inputs_output_sum_with_types")
      let views_component = await fetchGetData(this.context.device, "views_component_sum")
      let components = await fetchGetData(this.context.device, "components")

      if (inputs_output_sum.dataExists !== 'false') {
        this.setState({ inputs_output_sum })
      }
      if (views_component.dataExists !== 'false') {
        this.setState({ views_component })
      }
      if (components.dataExists !== 'false') {
        this.setState({ components })
      }
      this.setState({ ready: true })

      if (this.state.views_component.dataExists !== 'false' && this.state.inputs_output_sum.dataExists !== 'false'
          && this.state.inputs_output_sum.filter(item => item.input_view_id === this.props.view_id).length !== 0) {
        this.setUpGraphLinks()
      }
    } catch (err) {
      console.log(err)
    }
  }

  async componentDidMount() {
    this.setState({view_id: this.props.view_id})
    await this.getItems()
  }

  getComponent(component_id) {
    return this.state.components.filter(item => item.component_id === component_id)[0]
  }

  setUpGraphLinks() {
    this.cy.on('dbltap', 'node.components', function(){
      const link = this.data('href')
      window.open(link, '_self')
    })
  }

  buildCytoscapeGraph(components, connections) {
    const height = Math.min(this.state.components.length*60+60, 1000)
    const style = {
      width: '100%',
      height: height
    }

    const stylesheet = [
      {
        selector: 'node',
        style: {
          width: 200,
          height: 35,
          fontSize: 12, 
          shape: 'round-rectangle',
          borderColor: 'black',
          borderWidth: '2',
          backgroundColor: 'white',
          content: 'data(label)',
          textValign: 'center',
          textHalign: 'center',
          textWrap: 'wrap'
        }
      },
      {
        selector: 'node:selected',
        style: {
          backgroundColor: 'grey'
        }
      },
      {
        selector: '.components',
        style: {
          borderColor: '#356cc4'
        }
      },
      {
        selector: 'edge',
        style: {
          width: 1,
          lineColor: 'grey',
          curveStyle: 'bezier',
          targetArrowShape: 'triangle',
          targetArrowColor: 'grey',
          content: 'data(label)',
        }
      }
    ]

    let useLayout = true

    const elements = []
    components.forEach(item => {
      const component = this.getComponent(item.component_id)
      if (component) {
        let position = {}
        if (component.graph_x !== null && !this.state.default_layout) {
          useLayout = false
          position = { x: component.graph_x, y: component.graph_y }
        }
        elements.push({ classes: 'compound-parents',
                        data: { id: 'component_' + component.component_id + '_parent' } })
        elements.push({ classes: 'components',
                        data: { id: 'component_' + component.component_id,
                                label: 'Komponente:\n' + component.name, href: '/admin/component_' + component.component_id,
                                save_to_table: 'components', save_to_id: component.component_id,
                                parent: 'component_' + component.component_id + '_parent' },
                        style: { width: Math.max(10, component.name.length)*7 },
                        position: position })
      }
    })
    connections.forEach(connection => {
      let out_position = {}
      let in_position = {}
      if (connection.out_graph_x !== null) {
        out_position = { x: connection.out_graph_x, y: connection.out_graph_y}
        in_position = { x: connection.in_graph_x, y: connection.in_graph_y}
      }
      elements.push({ classes: 'outputs',
                      data: { id: 'output_' + connection.output_id,
                              label: connection.output_type_name + ':\n' + connection.output_name,
                              save_to_table: 'inputs_output', save_to_id: connection.inputs_output_id,
                              parent: 'component_' + connection.output_component_id + '_parent' },
                      style: { width: Math.max(connection.output_type_name.length, connection.output_name.length)*7, },
                      position: out_position })
      elements.push({ classes: 'inputs',
                      data: { id: 'input_' + connection.input_id,
                              label: connection.input_type_name + ':\n' + connection.input_name,
                              save_to_table: 'inputs_output', save_to_id: connection.inputs_output_id,
                              parent: 'component_' + connection.input_component_id + '_parent' },
                      style: { width: Math.max(connection.input_type_name.length, connection.input_name.length)*7 },
                      position: in_position })
      elements.push({ data: { source: 'component_' + connection.output_component_id, target: 'output_' + connection.output_id } })
      elements.push({ data: { source: 'output_' + connection.output_id, target: 'input_' + connection.input_id } })
      elements.push({ data: { source: 'input_' + connection.input_id, target: 'component_' + connection.input_component_id } })
    })

    if (useLayout) {
      return (
        <div>
          <CytoscapeComponent
            cy={(cy) => { this.cy = cy }}
            elements={elements}
            style={style}
            layout={{name: 'dagre', rankDir: 'LR', nodeSep: 20}}
            stylesheet={stylesheet}
            zoomingEnabled={true}
            userZoomingEnabled={false}
          />
        </div>
      )
    } else {
      return (
        <div>
          <CytoscapeComponent
            cy={(cy) => { this.cy = cy }}
            elements={elements}
            style={style}
            stylesheet={stylesheet}
            zoomingEnabled={true}
            userZoomingEnabled={false}
          />
        </div>
      )
    }
  }

  saveGraph() {
    let nodes = this.cy.filter('node')
    let nodes_counter = nodes.length
    nodes.forEach(node => {
      let pos = node.position()
      let values = {graph_x: pos.x, graph_y: pos.y}
      if (node.id().split('_')[0] === 'output') {
        values = {out_graph_x: pos.x, out_graph_y: pos.y}
      } else if (node.id().split('_')[0] === 'input') {
        values = {in_graph_x: pos.x, in_graph_y: pos.y}
      }
      let table = node.data('save_to_table')
      let id_string = table === 'inputs_output' ? 'inputs_output_id' : table.slice(0, -1) + '_id'
      fetchEdit(table, id_string, node.data('save_to_id'), values)
      .then(item => {
        if (Array.isArray(item)) {
          console.log('Edited:')
          console.log(item[0])
          nodes_counter--
          if (nodes_counter === 0) {
            console.log("Position of Nodes successfully saved")
          }
        } else {
          console.log('failure')
        }
      })
      .catch(err => console.log(err))
    })
  }

  render() {
    if (this.state.components.length > 0 && this.state.ready) {
      // filter out connections that don't belong to the current device
      const component_ids = this.state.components.map(component => component.component_id)
      let current_connections = this.state.inputs_output_sum.filter(item => item.input_view_id === this.props.view_id)
      current_connections = current_connections.filter(connection => component_ids.includes(connection.input_component_id) && component_ids.includes(connection.output_component_id))
      
      // filter out components that are not connected to any connection of the current view
      // This is necessary because there are components which do not belong to the current view but still belong to a connection of that view
      const current_components = this.state.views_component.filter(item => 
        // check if component is connected to any connection of the current view
        current_connections.some(connection => 
          connection.input_component_id === item.component_id 
          || connection.output_component_id === item.component_id))

      if (current_connections.length !== 0) {
        const graph = this.buildCytoscapeGraph(current_components, current_connections)
        const save_btn =  <button className='btn-minimal btn-edit'
                            onClick={() => {this.saveGraph()}}
                            data-toggle="tooltip" data-placement="top" title="Save Graph Layout"
                            hidden={this.props.user_type_id === 0}
                          ><FontAwesomeIcon icon={faSave} /></button>
        const reset_btn = <button className='btn-minimal btn-edit'
                            onClick={() => {this.setState({ default_layout: true })}}
                            data-toggle="tooltip" data-placement="top" title="Reset Graph Layout"
                            hidden={this.props.user_type_id === 0}
                          ><FontAwesomeIcon icon={faBackward} /></button>
        const zoom_in_btn = <button className='btn-minimal btn-edit'
                              onClick={() => {this.cy.zoom(this.cy.zoom() + 0.2)}}
                              data-toggle="tooltip" data-placement="top" title="Zoom In"
                              hidden={this.props.user_type_id === 0}
                            ><FontAwesomeIcon icon={faMagnifyingGlassPlus} /></button>

        const zoom_out_btn =  <button className='btn-minimal btn-edit'
                                onClick={() => {this.cy.zoom(this.cy.zoom() - 0.2)}}
                                data-toggle="tooltip" data-placement="top" title="Zoom Out"
                                hidden={this.props.user_type_id === 0}
                              ><FontAwesomeIcon icon={faMagnifyingGlassMinus} /></button>
        return (
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Graph {save_btn} {reset_btn} {zoom_in_btn} {zoom_out_btn} </CardTitle>
            </CardHeader>
            <CardBody>
              {graph}
            </CardBody>    
          </Card>
        )
      }
    }
    return <div></div>
  }
}

export default Export_Card