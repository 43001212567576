import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap"
import api_url from "../../../helpers/config.js"
import Rev_ConnectionTable from 'components/Extensions/Tables/Revisions/Rev_ConnectionTable.js'
import { fetchGetDataWithDeleted } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Rev_Components_Card extends Component {
  static contextType = DeviceContext
  state = {
    views_component: [],
    components: []
  }

  async getItems(){
    try {
      const viewsComponentResponse = await fetchGetDataWithDeleted(this.context.device, 'views_component');
      if (viewsComponentResponse.dataExists != 'false') {
        this.setState({ views_component: viewsComponentResponse });
      }
  
      const componentsResponse = await fetchGetDataWithDeleted(this.context.device, 'components');
      if (componentsResponse.dataExists != 'false') {
        this.setState({ components: componentsResponse });
      }
    } catch (err) {
      console.error(err);
    }
  }

  componentDidMount() {
    this.getItems()
  }

  refresh = () => {
    this.getItems()
  }

  render() {
    if (this.state.views_component.dataExists != 'false') {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Components Revisions</CardTitle>
          </CardHeader>
          <CardBody>
            <Rev_ConnectionTable
              item_type={'view'}
              child_items={this.state.components}
              child_type={'component'}
              parent_child_items={this.state.views_component.dataExists != 'false' ? this.state.views_component.filter(item => item.view_id == this.props.view_id) : this.state.views_component}
              columns={['Name']}
              columns_DB={['name']}
              refresh={this.refresh}
            />
          </CardBody>
        </Card>
      )
    } else {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Components Revisions</CardTitle>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      )
    }
  }
}
export default Rev_Components_Card