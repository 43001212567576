import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { NavLink } from "react-router-dom";

// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavbarToggler,
    NavLink as ReactstrapNavLink,
    NavItem,
    Nav,
    Container,
    Modal
  } from "reactstrap";

  import "./SimpleNavbar.css";

class SimpleNavbar extends React.Component{
    constructor(props) {
        super(props);
        // this.props = {}
        //     brandText: props.brandText,
        //     toggleSidebar: props.toggleSidebar,
        //     sideBarOpened: props.sideBarOpened,
        // }
        this.state = {
            viewRoutes: props.viewRoutes,
            simpleSidebarOpened: false,
            color: "navbar-transparent",
        };
    }

    componentDidMount() {}

    toggleSimpleSidebar(){
        this.setState({
            ...this.state,
            simpleSidebarOpened: !this.state.simpleSidebarOpened
        })
    }

    render() {
        return(
            <Navbar className={"navbar-expand-lg navbar-light bg-light"} expand="lg">
                {/* <NavbarBrand>unicar Agil</NavbarBrand> */}
                <NavbarToggler onClick={this.toggleSimpleSidebar.bind(this)} />
                <Collapse isOpen={this.state.simpleSidebarOpened} navbar>
                    <Nav className="me-auto" navbar>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Views
                            </DropdownToggle>
                            <DropdownMenu>
                                {this.props.viewRoutes.map((view, index) => {
                                    if (this.props.redirect) return null;
                                    return (
                                        <DropdownItem>
                                            <NavLink 
                                                to={view.layout + view.path ?? ""}
                                                key={index}
                                            >
                                                {view.name}
                                            </NavLink>
                                        </DropdownItem>
                                    )
                                    // return (<DropdownItem>{view.name}</DropdownItem>);
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {/* search bar */}

                        {/* <NavItem>
                            <NavLink href="">Configuration</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="">Dashboard</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="">Logout</NavLink>
                        </NavItem> */}
                    </Nav>
                </Collapse>
            </Navbar>
        )
    }
}

export default SimpleNavbar;