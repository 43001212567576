import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Rev_DataTable from 'components/Extensions/Tables/Revisions/Rev_DataTable';

// Modal with a revision table for data content

class Rev_ModalDataTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }

  render() {
    const close_btn = <button className="close" onClick={this.toggle}>&times;</button>
    const left_arrow_btn = <button onClick={this.toggle} className='btn-minimal btn-arrow-left'><FontAwesomeIcon icon={faArrowLeft} /></button>
    const title = 'History ' + this.props.title
    return (
      <div style={{ display: 'inline-block' }}>
        {left_arrow_btn}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle} close={close_btn}>{title}</ModalHeader>
          <ModalBody>
            <Rev_DataTable
              items={this.props.items}
              rev_items={this.props.rev_items}
              item_type={this.props.item_type}
              columns={this.props.columns}
              columns_DB={this.props.columns_DB}
              refresh={this.props.refresh}
              revision_columns={this.props.revision_columns}
            />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
export default Rev_ModalDataTable