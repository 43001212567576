import React from 'react'
import { CardBody, Card } from 'reactstrap'
import { fetchGetData } from 'helpers/api'
import DataTableAttributes from 'components/Extensions/Tables/DataTableAttributes'
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit'

class Parameters_Card extends React.Component {

  state = {
    attributes: []
  }

  componentDidMount() {
    this.getItems()
  }

  getItems() {
    fetchGetData({}, 'attributes')
    .then(attributes => {
      if (attributes.dataExists !== 'false') {
        this.setState({ attributes })
      }
    })
    .catch(err => console.log(err))
  }

  addItemToState = (new_item) => {
    let attributes = this.state.attributes
    attributes.push(new_item)
    this.setState({ attributes })
  }

  deleteItemFromState = (id) => {
    let attributes = this.state.attributes
    attributes.filter(attribute => attribute['attribute_id'] !== id)
    this.setState({ attributes })
  }

  updateState = (updated_item) => {
    let attributes = this.state.attributes
    const index = attributes.findIndex(attribute => attribute['attribute_id'] === updated_item['attribute_id'])
    attributes[index] = updated_item
    this.setState({ attributes })
  }

  render() {
    let attributes_to_render = this.state.attributes.filter(attribute => attribute['component_id'] === this.props.component_id)
    const component = this.props.components.find(component => component['component_id'] === this.props.component_id)
    let degradation_rules = component ? component['degradation_rules'] : []

    return (
      <Card>
        <CardBody>
          <DataTableAttributes
            items={attributes_to_render}
            addItemToState={this.addItemToState}
            deleteItemFromState={this.deleteItemFromState}
            updateState={this.updateState}
            parent_type={'component'}
            component_id={this.props.component_id}
            withEdit={!(this.props.user_type_id === 0)}
            user_type_id={this.props.user_type_id}
          />
          {degradation_rules.length > 0 && attributes_to_render.length > 0 ?
            <>
              <br />
              <h5>Auslösende Degradationsregeln</h5>
              <DataTableDeleteAndEdit
                items={degradation_rules}
                item_type={'degradation_rule'}
                columns={['Name', 'Beschreibung', 'Auslösebedingungen']}
                columns_DB={['name', 'description', 'trigger_conditions']}
                links={{'name': {'id': 'degradation_rule_id', 'type': 'degradation_rule'}}}
              />
            </> : null}
        </CardBody>
      </Card>
    )
  }
}

export default Parameters_Card
