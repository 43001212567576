/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react"
import api_url from "../../../helpers/config.js"
import { DeviceContext } from 'context/DeviceContext'
import { fetchGetData } from 'helpers/api'

// reactstrap components
import Rev_Header_Card from "./Rev_Header_Card"
import Rev_Owner_Card from "./Rev_Owner_Card"
import Rev_Output_Card from "./Rev_Output_Card"
import Rev_Input_Card from "./Rev_Input_Card"

class Rev_ComponentP extends React.Component {
  static contextType = DeviceContext

  state = {
    component_id: 0,
    views_component: []
  }


  getItems() {
    fetchGetData(this.context.device, 'views_component')
    .then(views_component => {
      if (views_component.dataExists != 'false') {
        this.setState({ views_component })
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.getItems()
    this.setState({ component_id: this.props.id })
  }

  render() {
    // rename because rout transfer only works with variable name "id"
    const component_id = this.props.id

    // get the view_id the component is assigned to
    var current_view = []
    if (this.state.views_component.dataExists != 'false') {
      current_view = this.state.views_component.filter(item => item.component_id == component_id)
    }
    // component_view_id is the view_id the component is assigned to
    var component_view_id = ''
    if (current_view.length > 0) {
      component_view_id = current_view[0].view_id
    } else {
      component_view_id = 0
    }
    // renders a grid type view, width is 12
    return (
      <>
        <div className="content">
          <Rev_Header_Card component_id={component_id} component_view_id={component_view_id} />
          <Rev_Owner_Card component_id={component_id} />
          <Rev_Input_Card component_id={component_id} />
          <Rev_Output_Card component_id={component_id} />
        </div>
      </>
    )
  }
}

export default Rev_ComponentP
