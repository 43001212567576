export function checkTopicNaming(input_id, output_id, inputs, inputs_attribute_sum, outputs, outputs_attribute_sum) {
  const input_attributes = inputs_attribute_sum.filter(item => item.input_id == input_id && item.attribute_type_name === 'Topic-Name')
  const output_attributes = outputs_attribute_sum.filter(item => item.output_id == output_id && item.attribute_type_name === 'Topic-Name')
  if (input_attributes.length > 0 && output_attributes.length > 0) {
    if (input_attributes[0].content === output_attributes[0].content) {
      return true
    } else {
      return window.confirm("The Topic-Names of Input and Output are not the same. Continue?")
    }
  } else {
    const input = inputs.filter(item => item.input_id === input_id)[0]
    const output = outputs.filter(item => item.output_id === output_id)[0]
    if (input.name === output.name) {
      return true
    } else {
      return window.confirm("Input and Output have different names. Continue?")
    }
  }
}