import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle} from "reactstrap"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import { fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Components_Card extends Component {
  static contextType = DeviceContext

  state = {
    components_user: [],
    components:[]
  }

  getItems(){
    fetchGetData(this.context.device, "components_user_sum")
    .then(components_user => {
      if (components_user.dataExists !== 'false') {
        this.setState({ components_user })
      }
      })
    .catch(err => console.log(err))
  

    fetchGetData({}, 'components')
    .then(components => {
      if (components.dataExists !== 'false') {
        this.setState({ components })
      }
      })
    .catch(err => console.log(err))
  }

  componentDidMount(){
    this.getItems()
  }

  render() {
    let items = this.state.components_user.filter(component_user => component_user.user_id === this.props.user_id)
    items = items.map(item => {
      const component = this.state.components.find(comp => comp.component_id === item.component_id)
     item['device_names'] = component ? component.devices.map(device => device.name).join(', ') : ''
      return item
    })
    if (items.length > 0) {
      return (
        <Card>
          <CardHeader>
            <div style={{display : 'inline-block'}}>
              <CardTitle tag="h4">Verantwortete Komponenten</CardTitle>
            </div>
          </CardHeader>
          <CardBody>
            <DataTableDeleteAndEdit
              items={items}
              item_type={'components_user'}
              columns={['Name', 'View', 'Geräte']}
              columns_DB={['component_name', 'view_name', 'device_names']}
              links={{'component_name': {'id': 'component_id', 'type': 'component'}, 'view_name': {'id': 'view_id', 'type': 'view'}}}
            />
          </CardBody>
        </Card>
      )
    } else {
      return (
        <Card>
          <CardHeader>
            <div style={{display : 'inline-block'}}>
              <CardTitle tag="h4">Keine verantworteten Komponenten</CardTitle>
            </div>
          </CardHeader>
        </Card>
      )
    }
  }
}
export default Components_Card