import React, { Component } from 'react'
import { Table, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashRestore, faWindowRestore } from "@fortawesome/free-solid-svg-icons"
import { DeviceContext } from 'context/DeviceContext'
import { fetchEdit, fetchRemoveRevision } from 'helpers/api.js'

// revision table for data content

class Rev_DataTable extends Component {
  static contextType = DeviceContext

  constructor(props) {
    super(props)
    this.state = {
      item_type_id_string: this.props.item_type + '_id'
    }
  }

  getRevisison(entry, id) {
    if (Object.entries(this.props.rev_items).length > 0 && this.props.rev_items.dataExists != 'false') {
      const revisions = this.props.rev_items.filter(rev_item => rev_item[this.state.item_type_id_string] == id)
      if (Object.entries(revisions).length > 0 && this.props.columns_DB.includes(entry[0])) {
        const revision = revisions.map(revision => {
          if (revision[entry[0]] != entry[1]) {
            // return an array [revision_content, timestamp, [content, restored, deleted], revision_id]
            if (revision[entry[0]] == false) {
              return ["", revision['created_at'], 'deleted', revision[this.props.item_type + '_revision_id']]
            } else if (revisions[entry[0]] == true) {
              return ["", revision['created_at'], 'restored', revision[this.props.item_type + '_revision_id']]
            } else {
              return [revision[entry[0]], revision['created_at'], 'content', revision[this.props.item_type + '_revision_id']]
            }
          }
        })
        console.log(revision)
        var filtered = revision.filter(function (el) {
          return el != null
        })
        console.log(filtered)
        return filtered
      }
    }
  }

  changeToRevisionState = (attribute, revisisonInfoArray, item) => {
    const revision_id = revisisonInfoArray[3]
    const revision_attribute_content = revisisonInfoArray[0]
    const revision_type = revisisonInfoArray[2]

    var changeAttributes = {}
    Object.entries(item).map(entry => {
      if (entry[0] != this.state.item_type_id_string && entry[0] != 'created_at' && entry[0] != 'updated_at' && this.props.revision_columns.includes(entry[0])) {
        changeAttributes[entry[0]] = entry[1]
      }
    })
    changeAttributes[attribute[0]] = revision_attribute_content


    if (revision_type == 'content') {
      let confirmChange = window.confirm('Change to state?')
      if (confirmChange) {
        fetchEdit(this.props.item_type + 's', item[this.state.item_type_id_string], changeAttributes)
        .then(item => {
          if (Array.isArray(item)) {
            console.log('Edited:')
            console.log(item[0])
            fetchRemoveRevision(this.props.item_type + 's_revisions', revision_id)
            .then(item => {
              console.log('removed')
              console.log(item)
              this.props.refresh()
            })
            .catch(err => console.log(err))
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
      }
    } else if (revision_type == 'deleted') {
      let confirmChange = window.confirm('Restore Item?')
      var changeAttributes = {}
      changeAttributes['deleted'] = false
      if (confirmChange) {
        fetchEdit(this.props.item_type + 's', item[this.state.item_type_id_string], changeAttributes)
        .then(item => {
          if (Array.isArray(item)) {
            console.log('Edited:')
            console.log(item[0])
            fetchRemoveRevision(this.props.item_type + 's_revisions', revision_id)
            .then(item => {
              console.log('removed')
              console.log(item)
              this.props.refresh()
            })
            .catch(err => console.log(err))
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
      }
    }

  }

  render() {
    var items = []

    if (Array.isArray(this.props.items)) {

      items = this.props.items.map(item => {
        //  search for the entry which defines the id passed by type_id
        var id = 0
        Object.entries(item).map(entry => {
          if (entry[0] == this.props.item_type + '_id') {
            id = entry[1]
          }
        })

        var entry = []
        var check_rev = false
        Object.entries(item).map((entry) => {
          if (entry[0] == 'updated_at' && entry[1] !== null) {
            check_rev = true
          }
        })

        var entry = []
        entry = Object.entries(item).map((entry, index) => {
          var revision_entries = ""
          const revisisonInfoArray = this.getRevisison(entry, id)
          if (Array.isArray(revisisonInfoArray)) {
            revision_entries = revisisonInfoArray.map(array => {
              if (Array.isArray(array)) {
                //[revision_content, timestamp, [content, restored, deleted], revision_id]
                if (array[2] == 'deleted') {
                  const restore_btn = <button onClick={() => this.restoreEntry(item)} className="btn-minimal btn-restore"><FontAwesomeIcon icon={faTrashRestore} /></button>
                  return (
                    <div>{" "}{restore_btn}<p><small>Has been removed at </small></p>
                      {" "}
                      {array[1]}
                      {" "}
                    </div>
                  )
                } else if (array[2] == 'restored') {
                  return (
                    <div><p><small>Has been restored at</small></p>
                      {" "}
                      {array[1]}
                    </div>
                  )
                } else if (array[2] == 'content') {
                  const win_restore_btn = <button onClick={() => this.changeToRevisionState(entry, array, item)} className="btn-minimal btn-window-restore"  data-toggle="tooltip" data-placement="top" title="Restore this entry">
                    <FontAwesomeIcon icon={faWindowRestore} />
                  </button>
                  return (
                    <div>{array[0]}{" "}{win_restore_btn}<p><small>Has been set at
                    {" "}
                      {array[1]}
                      {" "}
                    </small></p>
                    </div>)
                }
              }
            })
          }

          if (this.props.columns_DB.includes(entry[0]) && check_rev == true && this.props.revision_columns.includes(entry[0])) {
            return (
              <td scope="row" key={index}>{entry[1]}{revision_entries}</td>
            )
          } else if (this.props.columns_DB.includes(entry[0])) {
            return (
              <td scope="row" key={index}>{entry[1]}</td>
            )
          }
        })

        return (
          <tr key={id}>
            {entry}
          </tr>
        )
      })
    }

    var header = []
    header = this.props.columns.map((column, index) => {
      return (
        <th key={index}>{column}</th>
      )
    })
    return (
      <Table hover striped className="dataTableDeleteAndEdit">
        <thead className="text-primary">
          <tr>
            {header}
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </Table>
    )
  }
}
export default Rev_DataTable