import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap"
import Rev_ConnectionTable from 'components/Extensions/Tables/Revisions/Rev_ConnectionTable.js'
import { fetchGetDataWithDeleted, fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Rev_Components_Card extends Component {
  static contextType = DeviceContext

  state = {
    modules_component: [],
    components: []
  }

  getItems() {
    fetchGetDataWithDeleted(this.context.device, 'modules_component')
    .then(modules_component => {
      if (modules_component.dataExists != 'false') {
        this.setState({ modules_component })
      }
      fetchGetData(this.context.device, 'components')
      .then(components => {
        if (components.dataExists != 'false') {
          this.setState({ components })
        }
      })
      .catch(err => console.log(err))
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.getItems()
  }

  refresh = () => {
    this.getItems()
  }

  render() {
    if (this.state.modules_component.dataExists != 'false') {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Components Revisions</CardTitle>
          </CardHeader>
          <CardBody>
            <Rev_ConnectionTable
              item_type={'module'}
              child_items={this.state.components}
              child_type={'component'}
              parent_child_items={this.state.modules_component.dataExists != 'false' ? this.state.modules_component.filter(item => item.module_id == this.props.module_id) : this.state.modules_component}
              columns={['Name']}
              columns_DB={['name']}
              refresh={this.refresh}
            />
          </CardBody>
        </Card>
      )
    } else {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Components Revisions</CardTitle>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      )
    }
  }
}
export default Rev_Components_Card