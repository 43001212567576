import React, { Component } from 'react'
import { Card, CardBody } from "reactstrap"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import { fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Views_Card extends Component {
  static contextType = DeviceContext

  state = {
    items: []
  }

  getItems() {
    fetchGetData(this.context.device, 'views')
    .then(items => {
      if (items.dataExists !== 'false') {
        this.setState({items})
      }
    })
    .catch(err => console.log(err))
  }

  addItemToState = (item) => {
    this.props.update_routes(window)
    this.getItems()
  }

  updateState = (item) => {
    const itemIndex = this.state.items.findIndex(data => data.view_id === item.view_id)
    const newArray = [
    // destructure all items from beginning to the indexed item
      ...this.state.items.slice(0, itemIndex),
    // add the updated item to the array
      item,
    // add the rest of the items to the array from the index after the replaced item
      ...this.state.items.slice(itemIndex + 1)
    ]
    this.setState({ items: newArray })
  }

  deleteItemFromState = (view_id) => {
    const updatedItems = this.state.items.filter(item => item.view_id !== view_id)
    this.setState({ items: updatedItems })
    this.getItems()
    this.props.update_routes(window)
  }

  componentDidMount(){
    this.getItems()
  }

  render() {
    return (
      <Card>
        <CardBody>
          <DataTableDeleteAndEdit
            title={'Sichten'}
            items={this.state.items}
            addItemToState={this.addItemToState}
            updateState={this.updateState}
            deleteItemFromState={this.deleteItemFromState}
            item_type='view'
            columns={['Name', 'Beschreibung', 'Code Generator']}
            columns_DB={['name', 'description', 'code_generator']}
            bools={['code_generator']}
            links={{'name': {'id': 'view_id', 'type': 'view'}}}
            withEdit={!(this.props.user_type_id === 0)}
            withDelete={!(this.props.user_type_id === 0)}
          />
        </CardBody>
      </Card>
    )
  }
}
export default Views_Card