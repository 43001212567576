/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react"

// reactstrap components
import Rev_Header_Card from "./Rev_Header_Card"
import Rev_Components_Card from "./Rev_Components_Card"


class Rev_Module extends React.Component {
  state = {
    module_id: 0
  }

  componentDidMount() {
    this.setState({ module_id: this.props.id })
  }

  render() {
    return (
      <>
        <div className="content">
          <Rev_Header_Card module_id={this.state.module_id} />
          <Rev_Components_Card module_id={this.state.module_id} />
        </div>
      </>
    )
  }
}

export default Rev_Module
