import React, { Component } from 'react'
import { CardBody, Card, CardHeader, CardTitle } from 'reactstrap'
import api_url from "../../../helpers/config.js"
import Rev_ConnectionTable from 'components/Extensions/Tables/Revisions/Rev_ConnectionTable.js'
import { fetchGetDataWithDeleted } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'
import { fetchGetData } from 'helpers/api.js'

class Rev_Owner_Card extends Component {
  static contextType = DeviceContext

  state = {
    components_user: [],
    users: [],
    component_id: 0
  }

  async getItems(){
    try {
      const componentsUser = await fetchGetDataWithDeleted(this.context.device, 'components_user');
      if (componentsUser.dataExists !== 'false') {
        this.setState({ componentsUser });
      }
  
      const users = await fetchGetData(this.context.device, 'users');
      if (users.dataExists !== 'false') {
        this.setState({ users });
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    this.setState({ component_id: this.props.component_id })
    this.getItems()
  }

  refresh = () => {
    this.getItems()
  }

  render() {
    if (this.state.components_user.dataExists != 'false') {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Owner Revisions</CardTitle>
          </CardHeader>
          <CardBody>
            <Rev_ConnectionTable
              item_type={'component'}
              child_items={this.state.users}
              child_type={'user'}
              parent_child_items={this.state.components_user.dataExists != 'false' ? this.state.components_user.filter(item => item.component_id == this.state.component_id) : this.state.components_user}
              columns={['Name']}
              columns_DB={['name']}
              refresh={this.refresh}
            />
          </CardBody>
        </Card>
      )
    } else {
      // if no revisions dont render table
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Owner Revisions</CardTitle>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      )
    }
  }
}

export default Rev_Owner_Card