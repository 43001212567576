import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap"
import Rev_DataTable from 'components/Extensions/Tables/Revisions/Rev_DataTable'
import api_url from "../../../helpers/config"
import { fetchGetDataWithDeleted } from 'helpers/api'
import { DeviceContext } from 'context/DeviceContext'

class Rev_Views_Card extends Component {
  static contextType = DeviceContext

  constructor(props) {
    super(props)
    this.state = {
      // items = views
      items: [],
      rev_items: []
    }
  }

  getItems() {
    fetchGetDataWithDeleted(this.context.device, 'views')
    .then(items => {
      if (items.dataExists != 'false') {
        this.setState({ items })
      }
    })
    .catch(err => console.log(err))

    fetchGetDataWithDeleted(this.context.device, 'views_revisions')
    .then(rev_items => {
      if (rev_items.dataExists != 'false') {
        this.setState({ rev_items })
      }
    })
    .catch(err => console.log(err))
  }

  refresh = () => {
    this.getItems()
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <div style={{ display: 'inline-block' }}>
            <CardTitle tag="h4">Views Revisions</CardTitle>
          </div>
          {" "}
        </CardHeader>
        <CardBody>
          <Rev_DataTable
            items={this.state.items}
            rev_items={this.state.rev_items}
            item_type='view'
            columns={['ID (debug)', 'Name', 'Beschreibung', 'Gelöscht?']}
            columns_DB={['view_id', 'name', 'description', 'deleted']}
            revision_columns={['view_id', 'name', 'description', 'deleted']}
            refresh={this.refresh}
          />
        </CardBody>
      </Card>
    )
  }
}
export default Rev_Views_Card