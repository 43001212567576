import React, { Component } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Table} from 'reactstrap'

class Header_Card extends Component {

  render() {
    const user = this.props.user

    let card_body = <></>
    if (user.email || user.partner || user.institute) {
      card_body = 
        <CardBody>
          {user.email ? <>Email: <a href={'mailto:' + user.email}>{user.email}</a><br /></> : ''}
          {user.partner ? <>Partner: {user.partner} <br /></> : ''}
          {user.institute ? <>Institut: {user.institute}</> : ''}
        </CardBody>
    }

    return (
      <Card>
        <CardHeader>
          <div style={{display : 'inline-block'}}>
            <CardTitle tag="h2">{user.first_name} {user.name}{user.title ? (', ' + user.title) : ''}</CardTitle>
          </div>
        </CardHeader>
        {card_body}
      </Card>
    )
  }
}

export default Header_Card