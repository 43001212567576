/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container fluid>
          <div className="copyright">
            <p>
              <a href="https://embedded.rwth-aachen.de/doku.php?id=lehrstuhl:impressum" target="_blank">
                © {new Date().getFullYear()}{" "}
                RWTH Aachen Lehrstuhl Informatik 11 - Embedded Software
              </a>
            </p>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
