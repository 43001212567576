import React, { Component } from 'react'
import { Input, CardBody, Card, CardHeader, CardTitle } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen, faCheck, faX } from "@fortawesome/free-solid-svg-icons"
import Owner_Modal from 'components/Extensions/Modals/Owner_Modal.js'
import { DeviceContext } from 'context/DeviceContext'
import { fetchGetData, fetchEdit } from 'helpers/api.js'
import translate from 'helpers/translations'

class Header_Card extends Component {
  static contextType = DeviceContext

  state = {
    item: {},
    edit: null
  }

  getItems() {
    fetchGetData(this.context.device, 'modules')
    .then(items => {
      if (items.dataExists !== 'false') {
        this.setState({ item: items.find(item => item.module_id === this.props.module_id) })
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.getItems()
  }

  onChange = (e) => {
    const { name, value } = e.target
    let edit_item = this.state.edit
    edit_item[name] = value
    this.setState({ edit: edit_item })
  }

  submitChange = (edit_entries) => {
    if (this.state.edit['name']) {
      let edit_item = {}
      edit_entries.forEach(entry => {
        edit_item[entry] = this.state.edit[entry].trim()
      })
      fetchEdit('modules', undefined, this.state.item['module_id'], edit_item)
      .then(item => {
        this.setState({ edit: null })
        this.setState({ item: item[0] })
      })
      .catch(err => console.log(err))
    }
  }

  render() {
    const module = this.state.item
    let edit_entries = module.test_architecture ? ['description', 'categorization', 'metric'] : ['description']

    let card_header
    if (this.state.edit) {
      const input_style = this.state.edit['name'] ? {} : {borderColor: 'red', boxShadow: '0 0 5px red'} // style of the input field when it is empty
      card_header = 
        <>
          <Input
            type="text"
            name={'name'}
            autoFocus={true}
            onChange={this.onChange}
            value={this.state.edit['name'] || ''}
            placeholder='Name'
            style={{...{width: Math.max(140, this.state.edit['name'].length * 17), padding: '5px', marginLeft: '10px', fontSize: '30px'}, ...input_style}}
          />
          <button onClick={() => this.submitChange(edit_entries.concat(['name']))} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faCheck} /></button>
          <button onClick={() => this.setState({ edit: null })} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faX} /></button>
        </>
    } else {
      card_header =
        <>
          {module['name']}
          <button onClick={() => this.setState({ edit: { ...module } })} className='btn-minimal btn-edit' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faPen} /></button>
          <Owner_Modal parent_type={'module'} parent_id={this.props.module_id} user_type_id={this.props.user_type_id} />
        </>
    }

    let card_body = <></>
    if (module.description || module.categorization || module.metric || this.state.edit) {
      card_body =
        <CardBody style={{whiteSpace: "pre-line"}}>
          {edit_entries.map((entry, index) => {
            if (this.state.edit) {
              return (
                <div key={entry}>
                  <h4 style={{marginTop: index === 0 ? '' : '15px'}}>{translate(entry)}</h4>
                  <Input
                    type="textarea"
                    name={entry}
                    onChange={this.onChange}
                    value={this.state.edit[entry] || ''}
                    style={{padding: '5px'}}
                  />
                </div>
              )
            } else if (module[entry]) {
              return <div key={entry}><h4 style={{marginTop: index === 0 ? '' : '15px'}}>{translate(entry)}</h4>{module[entry]}</div>
            }
          })}
        </CardBody>
    }

    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h2">
            <div className='d-flex' style={{ display: 'inline-block' }}>
              {module.test_architecture ? 'Test-Architektur: ' : 'Modul: '}
              {card_header}
            </div>
          </CardTitle>
        </CardHeader>
        {card_body} 
      </Card>
    )
  }
}

export default Header_Card