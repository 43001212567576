import React from "react"

import Header_Card from "./Header_Card"
import Components_Card from "./Components_Card"

class User extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <>
        <div className="content">
          <Header_Card user={this.props.user} />
          <Components_Card user_id={this.props.user.user_id} />
        </div>
      </>
    )
  }
}

export default User
