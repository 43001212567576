import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Rev_ConnectionTable from '../../Tables/Revisions/Rev_ConnectionTable';

// Modal with a revision table for connections between different architecture elements

class Rev_ModalConnectionTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }

  render() {
    const close_btn = <button className="close" onClick={this.toggle}>&times;</button>
    const left_arrow_btn = <button onClick={this.toggle} className='btn-minimal btn-arrow-left'><FontAwesomeIcon icon={faArrowLeft} /></button>
    const title = this.props.child_type + ' History ' + this.props.items.filter(item => item[this.props.item_type + '_id'] == this.props.item_id)[0]['name']

    return (
      <div style={{ display: 'inline-block' }}>
        {left_arrow_btn}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle} close={close_btn}>{title}</ModalHeader>
          <ModalBody>
            <Rev_ConnectionTable
              items={this.props.items.filter(item => item[this.props.item_type + '_id'] == this.props.item_id)}
              rev_items={this.props.rev_items}
              item_type={this.props.item_type}
              child_items={this.props.child_items}
              child_type={this.props.child_type}
              parent_child_items={this.props.parent_child_items.filter(item => item[this.props.item_type + '_id'] == this.props.item_id)}
              columns={['Name']}
              columns_DB={['name']}
              refresh={this.props.refresh}
            />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
export default Rev_ModalConnectionTable