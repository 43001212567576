import React, { Component } from 'react'
import { CardBody, Card, CardHeader, CardTitle, Button, Input } from 'reactstrap'
import 'react-medium-image-zoom/dist/styles.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBackward, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faSave, faMinimize, faMaximize } from "@fortawesome/free-solid-svg-icons"
import Cytoscape from 'cytoscape'
import CytoscapeComponent from 'react-cytoscapejs'
import { fetchGetData, fetchEdit } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'
import dagre from 'cytoscape-dagre'
Cytoscape.use(dagre)

class Graph_Card extends Component {
  static contextType = DeviceContext

  state = {
    devices: [],
    views: [],
    view_id: undefined,
    views_component: [],
    components: [],
    inputs_output_sum: [],
    detailed_graph: true
  }

  async getItems() {
    try {
      let devices = await fetchGetData({}, "devices")
      let views = await fetchGetData({}, "views")
      let inputs_output_sum = await fetchGetData({}, "inputs_output_sum_with_types")
      let views_component = await fetchGetData({}, "views_component_sum")
      let components = await fetchGetData({}, "components")

      if (devices.dataExists !== 'false') {
        this.setState({ devices })
      }
      if (views.dataExists !== 'false') {
        this.setState({ views })
        this.setState({ view_id: views.sort((a,b) => a.view_id > b.view_id)[0].view_id })
      }
      if (inputs_output_sum.dataExists !== 'false') {
        this.setState({ inputs_output_sum })
      }
      if (views_component.dataExists !== 'false') {
        this.setState({ views_component })
      }
      if (components.dataExists !== 'false') {
        this.setState({ components })
      }

      if (this.state.views_component.dataExists !== 'false' && this.state.inputs_output_sum.dataExists !== 'false'
          && this.state.inputs_output_sum.filter(item => item.input_view_id === this.state.view_id).length !== 0) {
        this.setUpGraphLinks()
      }
    } catch (err) {
      console.log(err)
    }
  }

  async componentDidMount() {
    await this.getItems()
    this.buildCytoscapeGraph()
  }

  setUpGraphLinks() {
    const self = this
    this.cy.on('dbltap', 'node.components', function(){
      const link = this.data('href')
      window.open(link, '_self')
    })
  }
  
  zoomGraph(amount) {
    let canvas_center = { x: this.cy.width()/2, y: this.cy.height()/2 }
    let level =this.cy.zoom() + amount
    this.cy.zoom({ level: level, renderedPosition: canvas_center })
  }

  buildCytoscapeGraph() {
    const stylesheet = [
      {
        selector: 'node',
        style: {
          width: 200,
          height: 35,
          fontSize: 12, 
          shape: 'round-rectangle',
          borderColor: 'black',
          borderWidth: '2',
          backgroundColor: 'white',
          content: 'data(label)',
          textValign: 'center',
          textHalign: 'center',
          textWrap: 'wrap'
        }
      },
      {
        selector: 'node:selected',
        style: {
          backgroundColor: 'grey'
        }
      },
      {
        selector: '.components',
        style: {
          borderColor: 'rgb(80, 139, 135)'
        }
      },
      {
        selector: 'edge',
        style: {
          width: 1,
          lineColor: 'grey',
          curveStyle: 'bezier',
          targetArrowShape: 'triangle',
          targetArrowColor: 'grey',
          content: 'data(label)',
        }
      },
      {
        selector: '.devices[label]',
        style: {
          textValign: 'top',
          textMarginY: '-10px',
          fontWeight: 'bold'
        }
      }
    ]
    this.cy.style(stylesheet)

    // remove all elements to ensure a clean rebuild of the graph
    this.cy.elements().remove()
    if (this.state.components.length > 0 && this.state.view_id) {
      const component_ids = this.state.components.map(component => component.component_id)
      let current_connections = this.state.inputs_output_sum.filter(item => item.input_view_id === this.state.view_id)
      current_connections = current_connections.filter(connection =>
        component_ids.includes(connection.input_component_id) && component_ids.includes(connection.output_component_id))
      
      // filter out components that are not connected to any connection of the current view
      // This is necessary because there are components which do not belong to the current view but still belong to a connection of that view
      let current_components = this.state.views_component.filter(item => 
        // check if component is connected to any connection of the current view
        current_connections.some(connection => 
          connection.input_component_id === item.component_id 
          || connection.output_component_id === item.component_id))

      let elements = []
      // add nodes for devices
      this.state.devices.forEach(device => {
        elements.push({ classes: 'devices',
                        data: { id: 'device_' + device.device_id,
                                label: device.name } })
      })
      // add nodes for components
      const detailed = this.state.detailed_graph
      current_components.forEach(item => {
        const component = this.state.components.find(comp => item.component_id === comp.component_id)
        if (component) {
          component.devices.forEach(device => {
            if (detailed) {
              elements.push({
                classes: 'compound-parents',
                data: { id: 'device_' + device.device_id + '_component_' + component.component_id + '_parent',
                        parent: 'device_' + device.device_id },
                style: { backgroundColor: component.devices.length > 1 ? 'rgb(133, 209, 204)' : '', backgroundOpacity: '0.5' }
              })
            }
            elements.push({
              classes: 'components',
              data: { id: 'device_' + device.device_id + '_component_' + component.component_id,
                      label: 'Komponente:\n' + component.name, href: '/admin/component_' + component.component_id,
                      parent: 'device_' + device.device_id + [detailed ? '_component_' + component.component_id + '_parent' : ''] },
              style: { width: Math.max(11, component.name.length)*7.5 }
            })
          })
        }
      })

      current_connections.forEach(connection => {
        // add node and edge for ouput (only save device_id in case of the less detailed graph)
        const output_component = this.state.components.find(comp => connection.output_component_id === comp.component_id)
        let output_devices = []
        output_component.devices.forEach(device => {
          if (detailed) {
            elements.push({ classes: 'outputs',
                            data: { id: 'device_' + device.device_id + '_output_' + connection.output_id,
                                    label: connection.output_type_name + ':\n' + connection.output_name,
                                    parent: 'device_' + device.device_id + '_component_' + connection.output_component_id + '_parent' },
                            style: { width: Math.max(connection.output_type_name.length, connection.output_name.length)*7.5, } })
            elements.push({ data: { id: 'edge_d' + device.device_id + 'c' + connection.output_component_id + 'o' + connection.output_id,
                                    source: 'device_' + device.device_id + '_component_' + connection.output_component_id,
                                    target: 'device_' + device.device_id + '_output_' + connection.output_id } })
          }
          output_devices.push(device.device_id)
        })
        // add node and edge for input (only save device_id in case of the less detailed graph)
        const input_component = this.state.components.find(comp => connection.input_component_id === comp.component_id)
        let input_devices = []
        input_component.devices.forEach(device => {
          if (detailed) {
            elements.push({ classes: 'inputs',
                            data: { id: 'device_' + device.device_id + '_input_' + connection.input_id,
                                    label: connection.input_type_name + ':\n' + connection.input_name,
                                    parent: 'device_' + device.device_id + '_component_' + connection.input_component_id + '_parent' },
                            style: { width: Math.max(connection.input_type_name.length, connection.input_name.length)*7.5 } })
            elements.push({ data: { id: 'edge_d' + device.device_id + 'i' + connection.input_id + 'c' + connection.input_component_id,
                                    source: 'device_' + device.device_id + '_input_' + connection.input_id,
                                    target: 'device_' + device.device_id + '_component_' + connection.input_component_id } })
          }
          input_devices.push(device.device_id)
        })

        // add the edges for the connections
        let device_ids = []
        output_devices.forEach(output_device_id => {
          if (input_devices.includes(output_device_id)) {
            device_ids.push(output_device_id)
          }
        })
        // if output_devices and input_devices contain the same id this means that a component is on multiple devices
        // and a connection exists to or from that component inside this device
        // in this case we only want to draw the edge inside the device not crossing devices 
        if (device_ids.length > 0) {
          device_ids.forEach(device_id => {
            elements.push({
              data: { source: 'device_' + device_id + [detailed ? '_output_' + connection.output_id : '_component_' + connection.output_component_id],
                      target: 'device_' + device_id + [detailed ? '_input_' + connection.input_id : '_component_' + connection.input_component_id] }
            })
          })
        } else {
          output_devices.forEach(output_device_id => {
            input_devices.forEach(input_device_id => {
              elements.push({
                data: { source: 'device_' + output_device_id + [detailed ? '_output_' + connection.output_id : '_component_' + connection.output_component_id],
                        target: 'device_' + input_device_id + [detailed ? '_input_' + connection.input_id : '_component_' + connection.input_component_id] }
              })
            })
          })
        }
      })

      // insert elements into graph and apply layout
      this.cy.add(elements)
      let layout = this.cy.elements().layout({name: 'dagre', rankDir: 'LR', nodeSep: 20})
      layout.run()
    }
  }

  render() {
    const zoom_in_btn = <button className='btn-minimal btn-edit'
                          onClick={() => {this.cy.zoom(this.zoomGraph(0.2))}}
                          data-toggle="tooltip" data-placement="top" title="Rein Zoomen"
                          hidden={this.props.user_type_id === 0}
                        ><FontAwesomeIcon icon={faMagnifyingGlassPlus} /></button>
    const zoom_out_btn =  <button className='btn-minimal btn-edit'
                            onClick={() => {this.cy.zoom(this.zoomGraph(-0.2))}}
                            data-toggle="tooltip" data-placement="top" title="Raus Zoomen"
                            hidden={this.props.user_type_id === 0}
                          ><FontAwesomeIcon icon={faMagnifyingGlassMinus} /></button>
    const toggle_details_btn =  <button className='btn-minimal btn-edit'
                                  onClick={async () => {await this.setState({ detailed_graph: !this.state.detailed_graph }); this.buildCytoscapeGraph()}}
                                  data-toggle="tooltip" data-placement="top" title={this.state.detailed_graph ? "Weniger Details" : "Mehr Details"}
                                  hidden={this.props.user_type_id === 0}
                                ><FontAwesomeIcon icon={this.state.detailed_graph ? faMinimize : faMaximize} /></button>
    const view_select = <Input type="select" id="viewSelect"
                          value={this.state.view_id}
                          style={{width: '150px', display: 'inline-block', marginLeft: '10px'}}
                          onChange={async e => {await this.setState({ view_id: Number(e.target.value)}); this.buildCytoscapeGraph()}}
                        >
                          {this.state.views.map(view => <option key={view.view_id} value={view.view_id}>{view.name}</option>)}
                        </Input>
    let useLayout = true
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Graph {zoom_in_btn} {zoom_out_btn} {toggle_details_btn} {view_select}</CardTitle>
        </CardHeader>
        <CardBody>
        <div>
          <CytoscapeComponent
            cy={(cy) => { this.cy = cy }}
            elements={[]}
            style={{width: '100%', height: 800}}
            layout={useLayout ? {name: 'dagre', rankDir: 'LR', nodeSep: 20} : {name: 'preset'}}
            zoomingEnabled={true}
            userZoomingEnabled={false}
            minZoom={0.1}
            maxZoom={2}
          />
        </div>
        </CardBody>    
      </Card>
    )
  }
}

export default Graph_Card