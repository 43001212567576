import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle, Button, Input } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp, faPlus } from "@fortawesome/free-solid-svg-icons"
import IO_types_Sub_Card from './IO_types_Sub_Card'
import api_url from "../../helpers/config.js"
import { DeviceContext } from 'context/DeviceContext'
import { fetchGetData, fetchAddNew, fetchAddTo } from 'helpers/api'
import translate from 'helpers/translations'

class IO_type_Head_Card extends Component {
  static contextType = DeviceContext

  state = {
    io_types: [],
    subcards: {},
    new: null
  }

  async getItems() {
    fetchGetData(this.context.device, this.props.item_type + '_types')
    .then(io_types => {
      if (io_types.dataExists !== 'false') {
        this.setState({ io_types })
      }
    })
    .catch(err => console.log(err))
  }

  async getExportedCode() {
    const response = await fetch(api_url + 'code_generator', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        component_id: this.props.component_id,
      })
    })
  }

  componentDidMount() {
    this.getItems()
  }

  toggleSubcard = (type_id) => {
    let subcards = this.state.subcards
    if (type_id in this.state.subcards) {
      subcards[type_id] = !subcards[type_id]
      if (this.state.new && this.state.new[this.props.item_type + '_type_id'] === type_id) {
        this.setState({ new: null })
      }
    } else {
      subcards[type_id] = true
    }
    this.setState({ subcards })
  }

  addNew = (io_type) => {
    let subcards = this.state.subcards
    subcards[io_type[this.props.item_type + '_type_id']] = true
    this.setState({ subcards, new: io_type })
  }

  addIO = (new_name) => {
    if (new_name) {
      const new_type = this.state.new
      const { component_id, item_type } = this.props
      let attributes_db = {
        name: new_name,
        [item_type + '_type_id']: new_type[item_type + '_type_id'],
        component_id: Number(component_id)
      }

      // if the output-type is a server check if it already exists
      let confirm = true
      if (new_type[item_type + '_type_name'] === 'Service-Server' || new_type[item_type + '_type_name'] === 'Action-Server') {
        if (this.props.outputs.filter(output => output['output_type_id'] === new_type[item_type + '_type_id'] && output['name'] === new_name).length > 0) {
          confirm = window.confirm("Ein " + new_type[item_type + '_type_name']  + " mit dem gleichen Namen existiert bereits. Fortfahren?")
        }
      }
      if (confirm) {
        fetchAddNew(this.context.device, item_type + 's', attributes_db)
        .then(item => {
          if (item.dataExists !== 'false') {
            // add IO to component
            let io_id = item[0][item_type + '_id']
            let table_name = 'components_' + item_type
            fetchAddTo(this.context.device, component_id, 'component', io_id, item_type, table_name)
            .catch(err => console.log(err))
            this.props.addIO(item_type, item[0])
          }
        })
        .catch(err => console.log(err))
      }
    }
    this.setState({ new: null })
  }

  render() {
    const { subcards } = this.state
    const { item_type, component_view_id, component_id, views_io_type_sum } = this.props
    const io_types_attribute_type_sum = this.props[item_type + '_types_attribute_type_sum']

    let io_types
    if (component_view_id === 0) {
      // if the component is a template render all types
      io_types = this.state.io_types
    } else {
      io_types = views_io_type_sum.filter(io_type => io_type.view_id === component_view_id)
    }

    if(io_types.length === 0) {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">{'Für diese Sicht sind keine ' + translate(item_type) + 'typen festgelegt'}</CardTitle>
          </CardHeader>
        </Card>
      )
    }

    return io_types.map((io_type_to_render, index) => {
      const io_type_name = component_view_id === 0 ? io_type_to_render.name : io_type_to_render[item_type + '_type_name']
      const showSubcard = subcards[io_type_to_render[item_type + '_type_id']] || false
      const toggle_icon = showSubcard ? faAngleUp : faAngleDown
      const current_io_type_attribute_type_sum = io_types_attribute_type_sum.filter(item => item[item_type + '_type_id'] === io_type_to_render[item_type + '_type_id'])

      return (
        <Card key={index}>
          <CardHeader>
            <div style={{display : 'inline-block'}}>
              <CardTitle tag="h6">{translate(item_type) + 'typ'}</CardTitle>
              <h4>
                <button onClick={() => this.addNew(io_type_to_render)} className='btn-minimal btn-add' hidden={this.props.user_type_id === 0}>
                  <FontAwesomeIcon icon={faPlus}/>
                </button>
                {io_type_name}
              </h4>
            </div>
            {item_type === 'output' && io_type_name === 'Dienstgarantie' ?
              <Button style={{float: "right"}} onClick={() => this.getExportedCode()}>Alle exportieren</Button> : null}
            <div>
              <button onClick={() => this.toggleSubcard(io_type_to_render[item_type + '_type_id'])} className="btn-minimal btn-angle"><FontAwesomeIcon icon={toggle_icon} /></button>
            </div>
          </CardHeader>
          {/* don't show the subcard on default, only after the button is clicked */}
          {showSubcard ? 
            <CardBody>
              <IO_types_Sub_Card
                addAttribute={this.props.addAttribute}
                addConnection={this.props.addConnection}
                addInternalConnection={this.props.addInternalConnection}
                addIO={this.addIO}
                component_id={this.props.component_id}
                components={this.props.components}
                components_input_sum={this.props.components_input_sum}
                components_internal_sum={this.props.components_internal_sum}
                components_output_sum={this.props.components_output_sum}
                deleteAttribute={this.props.deleteAttribute}
                deleteConnection={this.props.deleteConnection}
                deleteInternalConnection={this.props.deleteInternalConnection}
                deleteIO={this.props.deleteIO}
                inputs={this.props.inputs}
                inputs_attribute_sum={this.props.inputs_attribute_sum}
                inputs_output_sum={this.props.inputs_output_sum}
                internal_connections_sum_with_types={this.props.internal_connections_sum_with_types}
                internals={this.props.internals}
                internals_attribute_sum={this.props.internals_attribute_sum}
                io_type_id={io_type_to_render[item_type + '_type_id']}
                io_type_name={io_type_name}
                item_type={item_type}
                new_io={this.state.new ? this.state.new[item_type + '_type_id'] === io_type_to_render[item_type + '_type_id'] : false}
                outputs={this.props.outputs}
                outputs_attribute_sum={this.props.outputs_attribute_sum}
                updateAttributes={this.props.updateAttributes}
                updateIO={this.props.updateIO}
                user_type_id={this.props.user_type_id}
              />
            </CardBody> : <CardBody></CardBody>
          }
        </Card>
      )
    })
  }
}

export default IO_type_Head_Card