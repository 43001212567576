import React, { Component } from 'react'
import { Row, Col, CardBody, Card, CardHeader, CardTitle, Button } from 'reactstrap'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import 'react-medium-image-zoom/dist/styles.css'
import Cytoscape from 'cytoscape'
import CytoscapeComponent from 'react-cytoscapejs'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { DeviceContext } from 'context/DeviceContext'
import { fetchEdit } from 'helpers/api.js'
import contextMenus from 'cytoscape-context-menus'
import 'cytoscape-context-menus/cytoscape-context-menus.css'
import cytoscapeEdgehandles from 'cytoscape-edgehandles'
import EditForm from 'components/Extensions/Forms/EditForm'
import AddIOForm from 'components/Extensions/Forms/AddIOForm'
import AddInternalConnectionForm from 'components/Extensions/Forms/AddInternalConnectionForm'
import translate from 'helpers/translations'
Cytoscape.use(contextMenus)
Cytoscape.use(cytoscapeEdgehandles)

class Export_Card extends Component {
  static contextType = DeviceContext

  state = {
    inputs: [],
    outputs: [],
    internals: [],
    internal_connections: [],
    removed: [],
    modal: { modal_type: '', data: {} }
  }

  componentDidMount() {
    if (this.cy) this.buildCytoscapeGraph()
  }

  componentDidUpdate(prevProps) {
    if (this.props.components_input_sum !== prevProps.components_input_sum) {
      this.setState({ inputs: this.props.components_input_sum.filter(item => item.component_id === this.props.component_id) })
    }
    if (this.props.components_output_sum !== prevProps.components_output_sum) {
      this.setState({ outputs: this.props.components_output_sum.filter(item => item.component_id === this.props.component_id) })
    }
    if (this.props.components_internal_sum !== prevProps.components_internal_sum) {
      this.setState({ internals: this.props.components_internal_sum.filter(item => item.component_id === this.props.component_id) })
    }
    if (this.props.internal_connections_sum_with_types !== prevProps.internal_connections_sum_with_types) {
      this.setState({ internal_connections: this.props.internal_connections_sum_with_types.filter(item => item.component_id === this.props.component_id) })
    }
    if (this.cy) this.buildCytoscapeGraph()
  }

  buildCytoscapeGraph() {
    // delete all elements of the graph before (re)creating it
    this.cy.nodes().remove()
    this.cy.edges().remove()

    const stylesheet = [
      {
        selector: 'node',
        style: {
          width: 200,
          height: 50,
          shape: 'round-rectangle',
          borderColor: 'black',
          borderWidth: '2',
          backgroundColor: 'white',
          content: 'data(label)',
          textValign: 'center',
          textHalign: 'center',
          textWrap: 'wrap'
        }
      },
      {
        selector: 'node:selected',
        style: {
          backgroundColor: 'grey'
        }
      },
      {
        selector: '.internals',
        style: {
          borderColor: '#356cc4'
        }
      },
      {
        selector: 'edge',
        style: {
          width: 2,
          lineColor: 'grey',
          curveStyle: 'bezier',
          targetArrowShape: 'triangle',
          targetArrowColor: 'grey',
          content: 'data(label)'
        }
      },
      {
        selector: 'edge:selected',
        style: {
          width: 3,
          lineColor: 'black',
          targetArrowColor: 'black'
        }
      },
      {
        selector: '.eh-ghost-edge.eh-preview-active',
        style: {
          'opacity': 0
        }
      }
    ]
    this.cy.style(stylesheet)

    const elements = []
    this.state.inputs.forEach((input, index) => {
      elements.push({ classes: 'inputs',
                      data: { id: 'input-' + input.input_id, label: input.input_type + ':\n' + input.input_name },
                      style: { width: Math.max(input.input_type.length, input.input_name.length)*10 },
                      position: input.graph_x === null ? { x: 100 , y: index*100+50 } : { x: input.graph_x, y: input.graph_y } })
    })
    this.state.outputs.forEach((output, index) => {
      elements.push({ classes: 'outputs',
                      data: { id: 'output-' + output.output_id, label: output.output_type + ':\n' + output.output_name },
                      style: { width: Math.max(output.output_type.length, output.output_name.length)*10 },
                      position: output.graph_x === null ? { x: this.cy.width()-100, y: index*100+50 } : { x: output.graph_x, y: output.graph_y } })
    })
    this.state.internals.forEach(internal => {
      elements.push({ classes: 'internals ' + internal.internal_type,
                      data: { id: 'internal-' + internal.internal_id, label: internal.internal_type + ':\n' + internal.internal_name },
                      style: { width: Math.max(internal.internal_type.length, internal.internal_name.length)*10 },
                      position: internal.graph_x === null ? { x: Math.round((Math.random()+1)*(this.cy.width()/3)), y: Math.round((Math.random()*(this.cy.height()-50))+25) }
                                                          : { x: internal.graph_x, y: internal.graph_y } })
    })
    this.state.internal_connections.forEach(connection => {
      const source_id = connection.origin_type + '-' + connection['origin_' + connection.origin_type + '_id']
      const target_id = connection.destination_type + '-' + connection['destination_' + connection.destination_type + '_id']
      elements.push({ data: { id: 'internal_connection-' + connection.internal_connections_id, source: source_id, target: target_id } })
    })

    // if no internals and internal connections exist, create vertex for component
    if (this.state.internals.length === 0 && this.state.internal_connections.length === 0 && (this.state.inputs.length !== 0 || this.state.outputs.length !== 0)) {
      const component_name = this.props.components.find(item => item.component_id === this.props.component_id)['name']
      elements.push({ classes: 'internals component',
                      data: { id: 'component', label: 'Komponente:\n' + component_name},
                      style: { width: Math.max('Komponente'.length, component_name.length)*10 },
                      position: { x: this.cy.width()/2, y: (this.cy.height()/2)-50} })
      this.state.inputs.forEach(input => {
        elements.push({ data: { source: 'input-' + input.input_id, target: 'component' } })
      })
      this.state.outputs.forEach(output => {
        elements.push({ data: { source: 'component', target: 'output-' + output.output_id } })
      })
    }

    // insert elements into graph
    this.cy.add(elements)

    let removed_elements = []

    let contextmenu_options = {
      evtType: 'cxttap',
      menuItems: [
        {
          id: 'delete',
          content: 'Löschen',
          selector: 'edge, node',
          onClickFunction: (event) => {
            let cy_id = event.target.data('id')
            let id = Number(cy_id.split('-')[1])
            if (event.target.group() === 'edges') {
              this.props.deleteInternalConnection(id)
            } else {
              let type = cy_id.split('-')[0] // input, output or internal
              this.props.deleteIO(type, id)
            }
          }
        },
        {
          id: 'rename',
          content: 'Umbennen',
          selector: 'node',
          onClickFunction: (event) => {
            let cy_id = event.target.data('id')
            let type = cy_id.split('-')[0] // input, output or internal
            let id = Number(cy_id.split('-')[1])
            let item = this.state[type + 's'].find(item => item[type + '_id'] === id)
            item['name'] = item[type + '_name']
            this.setState({ modal: { modal_type: 'edit', data: {item_type: type, item: item} } })
          }
        },
        ['internal', 'input', 'output'].map(item_type => {
          return {
            id: item_type,
            content: 'Neuer ' + translate(item_type),
            selector: '',
            coreAsWell: true,
            submenu: this.props['views_' + item_type + '_type_sum'].map(io_type => {
              return {
                id: io_type[item_type + '_type_id'],
                content: io_type[item_type + '_type_name'],
                onClickFunction: (event) => {
                  this.setState({
                    modal: {
                      modal_type: 'add',
                      data: {
                        position: event.position,
                        item_type: item_type,
                        io_type_id: io_type[item_type + '_type_id'],
                        io_type_name: io_type[item_type + '_type_name']
                      }
                    }
                  })
                }
              }
            })
          }
        }),
        {
          id: 'internal_connect',
          content: 'Neue Interne Verbindung',
          tooltipText: 'Stellt eine Verbindung zwischen diesem und einem anderen Element innerhalb dieser Komponente her',
          selector: 'node.inputs, node.internals',
          onClickFunction: (event) => {
            let cy_id = event.target.data('id')
            let type = cy_id.split('-')[0] // input or internal
            let id = Number(cy_id.split('-')[1])
            this.setState({ modal: { modal_type: 'add-int-con', data: {origin_type: type, origin_id: id} } })
          }
        },
      ].flat(),
      menuItemClasses: [
      ],
      contextMenuClasses: [
      ],
      // Indicates that the menu item has a submenu. If not provided default one will be used
      submenuIndicator: { }
    }

    let contextmenu_instance = this.cy.contextMenus(contextmenu_options)

    
    let edgehandle_defaults = {
      canConnect: (sourceNode, targetNode) => {
        // whether an edge can be created between source and target
        return !sourceNode.same(targetNode) // e.g. disallow loops
      },
      edgeParams: (sourceNode, targetNode) => {
        // for edges between the specified source and target
        // return element object to be passed to cy.add() for edge
        return {}
      },
      hoverDelay: 10, // time spent hovering over a target node before it is considered selected
      snap: true, // when enabled, the edge can be drawn by just moving close to a target node (can be confusing on compound graphs)
      snapThreshold: 10, // the target node must be less than or equal to this many pixels away from the cursor/finger
      snapFrequency: 15, // the number of times per second (Hz) that snap checks done (lower is less expensive)
      noEdgeEventsInDraw: true, // set events:no to edges during draws, prevents mouseouts on compounds
      disableBrowserGestures: true // during an edge drawing gesture, disable browser gestures such as two-finger trackpad swipe and pinch-to-zoom
    }
    
    let edgehandle_instance = this.cy.edgehandles(edgehandle_defaults)


    /*document.addEventListener('keydown', (event) => {
      if (event.ctrlKey && event.key === 'z') {
        let last_removed = removed_elements.pop()
        if (last_removed) last_removed.restore()
      } else if (event.key === 'Control') {
        edgehandle_instance.enableDrawMode()
      } else if (event.key === 'Delete' || event.key === 'Backspace') {
        removed_elements.push(this.cy.remove(':selected'))
      }
    })

    document.addEventListener('keyup', (event) => {
      if (event.key === 'Control') {
        edgehandle_instance.disableDrawMode()
      }
    })

    this.cy.on('tapdragover', 'node', (event) => {
      console.log(event)
    })*/
  }

  // TODO: refactor, store graph in seperate table in DB
  saveGraph() {
    const nodes = this.cy.filter('node')
    let nodes_counter = nodes.length
    nodes.forEach(node => {
      if (node.classes().includes('component')) {
        nodes_counter--
          if (nodes_counter === 0) {
            console.log("Position of Nodes successfully saved")
          }
      }
      else {
        let pos = node.position()
        fetchEdit(node.id().split('-')[0] + 's', undefined, Number(node.id().split('-')[1]), {graph_x: pos.x, graph_y: pos.y})
        .then(item => {
          if (Array.isArray(item)) {
            console.log('Edited:')
            console.log(item[0])
            nodes_counter--
            if (nodes_counter === 0) {
              console.log("Position of Nodes successfully saved")
            }
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
      }
    })
  }

  toggle = () => {
    this.setState({ modal: {modal_type: '', data: {} } })
  }

  // needed to create the node at the right position
  addIO = (item_type, new_item) => {
    const node = this.state.modal.data
    this.cy.add({ classes: item_type + 's',
                  data: { id: item_type + '-' + new_item[item_type + '_id'], label: node.io_type_name + ':\n' + new_item.name },
                  style: { width: Math.max(node.io_type_name.length, new_item.name.length)*10 },
                  position: { x: Math.round(node.position.x), y: Math.round(node.position.y) }
                })
    this.saveGraph()
    this.props.addIO(item_type, new_item)
  }

  render() {
    if (this.state.inputs.length > 0 || this.state.outputs.length > 0 || this.state.internals.length > 0) {
      const refresh_btn = <button className="btn btn-default animation-on-hover" type="button" onClick={() => {this.props.refresh()}}>Refresh</button>
      const save_btn = <button onClick={() => {this.saveGraph()}} className='btn-minimal btn-edit' data-toggle="tooltip" data-placement="top" title="Save Graph Layout" hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faSave} /></button>
      const close_btn = <button className="close" onClick={this.toggle}>&times;</button>

      let int_con_destinations = []
      if (this.state.modal.modal_type === 'add-int-con' && this.state.modal.data.origin_type === 'input') {
        int_con_destinations = this.state.internals.concat(this.state.outputs)
      } else if (this.state.modal.modal_type === 'add-int-con' && this.state.modal.data.origin_type === 'internal') {
        int_con_destinations = this.state.internals.concat(this.state.outputs.concat(this.state.inputs.filter(item => item.input_type != 'Subscriber')))
      }

      let item_type = 'input'
      if (this.state.modal.data.item_type) item_type = this.state.modal.data.item_type
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Graph
              {save_btn}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CytoscapeComponent
              id='cy-div'
              cy={(cy) => { this.cy = cy }}
              elements={[]}
              style={{width: '100%', height: /*Math.max(this.state.inputs.length, this.state.outputs.length)*100+100*/ '500px'}}
              zoomingEnabled={false}
            />
          </CardBody>
          {/* Edit IO Modal */}
          <Modal isOpen={this.state.modal.modal_type === 'edit'} toggle={this.toggle} className={this.props.className} autoFocus={false}>
            <ModalHeader toggle={this.toggle} close={close_btn}>{translate(item_type) + ' bearbeiten'}
            </ModalHeader>
            <ModalBody>
              <EditForm
                updateState={(item) => this.props.updateIO(item_type, item)}
                toggle={this.toggle}
                item={this.state.modal.data.item}
                item_type={item_type}
                edit_entries={['Name']}
                edit_entries_DB={['name']}
              />
            </ModalBody>
          </Modal>
          {/* Add IO Modal */}
          <Modal isOpen={this.state.modal.modal_type  === 'add'} toggle={this.toggle} className={this.props.className} autoFocus={false}>
            <ModalHeader toggle={this.toggle} close={close_btn}>{translate(item_type) + ' vom Typ ' + this.state.modal.data.io_type_name + ' hinzufügen'}
            </ModalHeader>
            <ModalBody>
              <AddIOForm
                addItemToState={(item) => this.addIO(item_type, item)}
                toggle={this.toggle}
                item_type={item_type}
                component_id={this.props.component_id}
                io_type_id={this.state.modal.data.io_type_id}
                io_type_name={this.state.modal.data.io_type_name}
                io_type_attributes={this.props[item_type + '_types_attribute_type_sum'].filter(item => item[item_type + '_type_id'] === this.state.modal.data.io_type_id)}
                attribute_types_content={this.props.attribute_types_content}
                possible_connections={item_type === 'output' ? this.props.inputs_attribute_sum : this.props.outputs_attribute_sum}
              />
            </ModalBody>
          </Modal>
          {/* Add Internal Connection Modal */}
          <Modal isOpen={this.state.modal.modal_type  === 'add-int-con'} toggle={this.toggle} className={this.props.className} autoFocus={false}>
            <ModalHeader toggle={this.toggle} close={close_btn}>{'Interne Verbindung hinzufügen'}
            </ModalHeader>
            <ModalBody>
              <AddInternalConnectionForm
                addItemToState={this.props.addInternalConnection}
                parent_id={this.state.modal.data.origin_id}
                children={int_con_destinations}
                parent_type={this.state.modal.data.origin_type}
                toggle={this.toggle}
                toggle_form={true}
                component_id={this.props.component_id}
                internal_connections={this.props.internal_connections_sum_with_types}
              />
            </ModalBody>
          </Modal>
        </Card>
      )
    } else {
      return <div></div>
    }
  }
}
export default Export_Card