import React, { Component } from 'react'
import { Table } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashRestore } from "@fortawesome/free-solid-svg-icons"
import api_url from "../../../../helpers/config.js"
import Rev_ModalDataTable from 'components/Extensions/Modals/Revisions/Rev_ModalDataTable.js'
import { fetchRestoreConnection } from 'helpers/api.js'

// revision table for connections between architecture elements and a revisison data sub table displayed in a modal

class Rev_ConnectionTableWithSubRev_DataTable extends Component {
  constructor(props) {
    super(props)
  }

  restoreEntry = (item) => {
    var attribute_values = {}
    attribute_values['deleted'] = false
    let confirmChange = window.confirm('Restore ' + this.props.child_type + ' ?')

    if (confirmChange) {
      fetchRestoreConnection(this.context.device, this.props.item_type + 's_' + this.props.child_type, item[this.props.item_type + 's_' + this.props.child_type + '_id'])
      .then(item => {
        if (Array.isArray(item)) {
          console.log('Edited:')
          console.log(item[0])
          this.props.refresh()
        } else {
          console.log('failure')
        }
      })
      .catch(err => console.log(err))

    }
  }

  render() {
    var items = []
    if (Array.isArray(this.props.parent_child_items) && Object.entries(this.props.child_items).length > 0) {
      items = this.props.parent_child_items.map(item => {
        //  search for the entry which defines the id passed by type_id
        var id = 0
        var child_id = 0
        var child_name = ''
        Object.entries(item).map(entry => {
          if (entry[0] == (this.props.item_type + 's_' + this.props.child_type + '_id')) {
            id = entry[1]
          } else if (entry[0] == (this.props.child_type + '_id')) {
            child_id = entry[1]
          }
        })
        // populate the table TODO: check if those are passed in column_DB
        var entry = []
        entry = Object.entries(item).map((entry, index) => {
          if (entry[0] == 'deleted') {
            if (entry[1]) {
              const restore_btn = <button onClick={() => this.restoreEntry(item)} className="btn-minimal btn-restore"><FontAwesomeIcon icon={faTrashRestore} /></button>
              return (
                <td scope="row" key={index}>
                  {restore_btn}
                </td>
              )
            } else {
              return (
                <td scope="row" key={index}></td>
              )
            }
          } else if (entry[0] == this.props.child_type + '_id') {
            var child_entry = this.props.child_items.filter(child_entry => child_entry[this.props.child_type + '_id'] == entry[1])
            child_name = child_entry[0]['name']
            return (
              <td scope="row" key={index}>{child_entry[0]['name']}</td>
            )
          }
        })

        var sub_items_for_current_entry = this.props.sub_parent_child_items.filter(item => item[this.props.child_type + '_id'] == child_id)
        var sub_item_ids = sub_items_for_current_entry.map(item => { return (item[this.props.sub_item_type + '_id']) })
        var sub_items = this.props.sub_items.filter(item => sub_item_ids.includes(item[this.props.sub_item_type + '_id']))

        return (
          <tr key={id}>
            {entry}
            <td scope="row">
              <Rev_ModalDataTable
                title={child_name}
                item_id={id}
                items={sub_items}
                rev_items={this.props.sub_rev_items}
                item_type={this.props.sub_item_type}
                columns={this.props.sub_columns}
                columns_DB={this.props.sub_columns_DB}
                refresh={this.props.refresh}
                revision_columns={this.props.revision_columns}
              />
            </td>
          </tr>
        )
      })
    }

    var header = []
    header = this.props.columns.map((column, index) => {
      return (
        <th key={index}>{column}</th>
      )
    })
    return (
      <Table responsive hover>
        <thead>
          <tr>
            {header}
            <th>Restore</th>
            <th key={'subitems'}>Attributes</th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </Table>
    )
  }
}
export default Rev_ConnectionTableWithSubRev_DataTable