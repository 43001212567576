/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react"
import api_url from '../../helpers/config.js'

import Header_Card from "./Header_Card"
import Components_Card from "./Components_Card"
import User_Management_Card from "./User_Management_Card"
import Stakeholder_Card from "./Stakeholder_Card"
import { fetchGetUserData } from "helpers/api.js"

class User_Dashboard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      user_id: 0
    }
  }

  getItems(){
    fetchGetUserData()
    .then(user => {
      this.setState({ user_id: user.id })
    })
    .catch(err => console.log(err))
  }

  componentDidMount(){
    this.getItems()
  }

  render() {
    return (
      <>
        <div className="content">
          <Header_Card user_id={this.state.user_id} />
          <Components_Card user_id={this.state.user_id} />
          <User_Management_Card />
        </div>
      </>
    )
  }
}

export default User_Dashboard
