import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap"
import Rev_DataTable from 'components/Extensions/Tables/Revisions/Rev_DataTable'
import { fetchGetDataWithDeleted } from 'helpers/api'
import { DeviceContext } from 'context/DeviceContext'

class Rev_Attribute_types_Card extends Component {
  static contextType = DeviceContext

  constructor(props) {
    super(props)
    // items = attribute types; rev = rev_attribute types
    this.state = {
      items: [],
      rev_items: []
    }
  }

  getItems() {
    fetchGetDataWithDeleted(this.context.device, 'attribute_types')
    .then(items => {
      if (items.dataExists != 'false') {
        this.setState({ items })
      }
    })
    .catch(err => console.log(err))

    fetchGetDataWithDeleted(this.context.device, 'attribute_types_revisions')
    .then(rev_items => {
      if (rev_items.dataExists != 'false') {
        this.setState({ rev_items })
      }
    })
    .catch(err => console.log(err))
  }

  refresh = () => {
    this.getItems()
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <div style={{ display: 'inline-block' }}>
            <CardTitle tag="h4">Attribute Types Managed in ATool</CardTitle>
          </div>
          {" "}
        </CardHeader>
        <CardBody>
          <Rev_DataTable
            items={this.state.items}
            rev_items={this.state.rev_items}
            item_type='attribute_type'
            columns={['ID (debug)', 'Name', 'Type', 'Deleted?']}
            columns_DB={['attribute_type_id', 'name', 'type', 'deleted']}
            revision_columns={['attribute_type_id', 'name', 'type', 'deleted']}
            refresh={this.refresh}
          />
        </CardBody>
      </Card>
    )
  }
}
export default Rev_Attribute_types_Card