/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react"

// reactstrap components
import Header_Card from "./Header_Card"
import Export_Card from "./Export_Card"
import IO_types_Card from "./IO_types_Card"
import Components_Card from "./Components_Card"
import Inconsistent_connections_Card from "./Inconsistent_connections_Card"
import Datastream_Card from "./Datastream_Card"


class View extends React.Component {
  state = {
    view_id: 0
  }

  componentDidMount(){
    this.setState({view_id: this.props.id})
  }


  render() {
    const view_id = this.props.view_id
    
    return (
      <>
        <div className="content">
          <Header_Card view_id={view_id} user_type_id={this.props.user_type_id}/>
          <Export_Card view_id={view_id} user_type_id={this.props.user_type_id}/>
          <Inconsistent_connections_Card view_id={view_id}/>
          <IO_types_Card item_type={'internal_type'} view_id={view_id} user_type_id={this.props.user_type_id}/>
          <IO_types_Card item_type={'input_type'} view_id={view_id} user_type_id={this.props.user_type_id}/>
          <IO_types_Card item_type={'output_type'} view_id={view_id} user_type_id={this.props.user_type_id}/>
          <Components_Card view_id={view_id} update_routes={this.props.update_routes} user_type_id={this.props.user_type_id}/>
        </div>
      </>
    )
  }
}

export default View
