import React, { Component } from 'react'
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap'
import ModalEditUser from 'components/Extensions/Modals/ModalEditUser'
import api_url from "../../helpers/config.js"
import { fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Header_Card extends Component {
  static contextType = DeviceContext

  state = {
    items: []
  }

  getItems(){
    fetchGetData(this.context.device, 'users')
    .then(items => {
      if (items.dataExists != 'false') {
        this.setState({ items })
      }
    })
    .catch(err => console.log(err))
  }

  updateState = (item) => {
    console.log(item)
    this.getItems()
  }

  componentDidMount(){
    this.setState({view_id: this.props.view_id})
    this.getItems()
  }

  generateHello(){
    const moment = require('moment')
    var ret = ""
    var morning = moment('08:00am', 'hh:mma')
    var noon = moment('11:59am', 'hh:mma')
    var evening = moment('17:59', 'hh:mm')
    var currentTime = moment()

    if(currentTime.isBefore(noon) && currentTime.isAfter(morning)){
      ret = "Guten Morgen"
    } else if (currentTime.isBefore(evening) && currentTime.isAfter(noon)) {
      ret = "Guten Tag"
    } else if (currentTime.isAfter(evening)) {
      ret = "Guten Abend"
    }  

    return ret
  }

  render() {
    const user = this.state.items.find(item => item.user_id === this.props.user_id)

    if (user) {
      let card_body = <></>
      if (user.email || user.partner || user.institute) {
        card_body = 
          <CardBody>
            {user.email ? <>Email: {user.email}<br /></> : ''}
            {user.partner ? <>Partner: {user.partner} <br /></> : ''}
            {user.institute ? <>Institut: {user.institute}</> : ''}
          </CardBody>
      }

      return (
        <Card>
          <CardHeader>
            <div style={{display : 'inline-block'}}>
              <CardTitle tag="h2">{this.generateHello()} {user.first_name} {user.name}</CardTitle>
            </div>
            <ModalEditUser
              item={user} 
              updateState={this.updateState}
              item_type='user'
              edit_entries={['Titel', 'Vorname', 'Nachname', 'Partner', 'Institut', 'Email', 'Neues Passwort']}
              edit_entries_DB={['title', 'first_name', 'name', 'partner', 'institute', 'email', 'password']}
            />
          </CardHeader>
          {card_body}
        </Card>
      )
    } else {
      return (
        <Card>
          <CardHeader>
            <div style={{display : 'inline-block'}}>
              <CardTitle tag="h2">{this.generateHello()}</CardTitle>
            </div>
          </CardHeader>
        </Card>
      )
    }
  }

}

export default Header_Card