import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap"
import Rev_ConnectionTable from 'components/Extensions/Tables/Revisions/Rev_ConnectionTable.js'
import { fetchGetData, fetchGetDataWithDeleted } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Rev_Output_types_Card extends Component {
  static contextType = DeviceContext
  
  state = {
    views_output_type: [],
    output_types: []
  }

  async getItems() {
    try {
      const viewsOutputTypeResponse = await fetchGetDataWithDeleted(this.context.device, 'views_output_type');
      if (viewsOutputTypeResponse.dataExists != 'false') {
        this.setState({ views_output_type: viewsOutputTypeResponse });
      }
  
      const outputTypesResponse = await fetchGetData(this.context.device, 'output_types');
      if (outputTypesResponse.dataExists != 'false') {
        this.setState({ output_types: outputTypesResponse });
      }
    } catch (err) {
      console.error(err);
    }
  }
  
  componentDidMount() {
    this.getItems()
  }

  refresh = () => {
    this.getItems()
  }

  render() {
    if (this.state.views_output_type.dataExists != 'false') {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Output Types Revisions</CardTitle>
          </CardHeader>
          <CardBody>
            <Rev_ConnectionTable
              item_type={'view'}
              child_items={this.state.output_types}
              child_type={'output_type'}
              parent_child_items={this.state.views_output_type.dataExists != 'false' ? this.state.views_output_type.filter(item => item.view_id == this.props.view_id) : this.state.views_output_type}
              columns={['Name']}
              columns_DB={['name']}
              refresh={this.refresh}
            />
          </CardBody>
        </Card>
      )
    } else {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Output Types  Revisions</CardTitle>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      )
    }
  }
}
export default Rev_Output_types_Card