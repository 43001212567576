import React from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import api_url from "../../../helpers/config.js"
import { fetchAddInternalConnection } from 'helpers/api.js'
import { DeviceContext } from '../../../context/DeviceContext.js'

class AddInternalConnectionForm extends React.Component {
  static contextType = DeviceContext

  state = {
    parent_id: 0,
    child_id: 0,
    child_type: ''
  }

  onChange = e => {
    console.log(e.target.value)
    this.setState({ child_type: (e.target.value).split(':')[0] })
    this.setState({ child_id: Number((e.target.value).split(':')[1]) })
  }

  submitFormAdd = e => {
    e.preventDefault()
    console.log("Add internal connection to " + this.props.parent_type)
    // Check if an input already has a callback
    let confirm = true
    if (this.props.parent_type === 'input') {
      if (this.props.internal_connections.filter(item => item.origin_input_id === this.state.parent_id).length > 0) {
        confirm = window.confirm("This input already has a callback. Continue?")
      }
    }
    if (confirm) {
      fetchAddInternalConnection(this.context.device, this.props.parent_type, this.state.parent_id, this.state.child_type, this.state.child_id, this.props.component_id)
      .then(item => {
        if (Array.isArray(item)) {
          this.props.addItemToState(item[0])
          if (this.props.toggle_form) {
            this.props.toggle()
          }
        } else {
          console.log('failure')
        }
      })
      .catch(err => console.log(err))
    } else {
      if (this.props.toggle_form) {
        this.props.toggle()
      }
    }
  }

  componentDidMount() {
    this.setState({ parent_id: this.props.parent_id })
  }

  render() {
    var children_options = <option>Kein Internal oder Output definiert</option>
    if (this.props.children.dataExists != 'false') {
      children_options = this.props.children.map((child, index) => {
        var child_type = (Object.keys(child)[0]).split('_')[1]
        const option = (child_type.charAt(0).toUpperCase() + child_type.slice(1) + ': ' + child[child_type + '_type'] + ': ' + child[child_type + '_name'] + ':')
        return (
          <option value={child_type + ':' + child[child_type + '_id']} key={index}>
            {option}
          </option>
        )
      })
    }
    return (
      <Form onSubmit={this.submitFormAdd}>
        <FormGroup>
          <Label for="userSelect">Eintrag wählen</Label>
          <Input type="select" name="select" id="childSelect" onChange={this.onChange} autoFocus={true}>
            <option>Auswählen</option>
            {children_options}
          </Input>
        </FormGroup>
        <Button>Hinzufügen</Button>
      </Form>
    )
  }
}
export default AddInternalConnectionForm