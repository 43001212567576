import React from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import api_url from "../../../helpers/config.js"
import { fetchAddNew } from 'helpers/api.js'
import { DeviceContext } from '../../../context/DeviceContext.js'
import { fetchAddTo } from 'helpers/api.js'

class AddIOForm extends React.Component {
  static contextType = DeviceContext

  state = {
    name: '',
    attributes: {}
  }

  onChange = e => {
    //  only update the changed entry in attributes value
    var new_attributes = {}
    const old_attributes = this.state.attributes
    Object.entries(old_attributes).map(entry => {
      if (entry[0] == e.target.name) {
        new_attributes[e.target.name] = e.target.value
      } else {
        new_attributes[entry[0]] = entry[1]
      }
    })
    this.setState({ attributes: new_attributes })
  }

  onChangeName = e => {
    this.setState({ name: e.target.value })
  }

  submitFormAdd = e => {
    e.preventDefault()
    var attributes_db = {}
    attributes_db.name = this.state.name
    attributes_db[this.props.item_type + '_type_id'] = this.props.io_type_id
    attributes_db.component_id = Number(this.props.component_id)

    // if the output-type is a server check if it already exists
    let confirm = true
    if (this.props.io_type_name[0] === 'Service-Server' || this.props.io_type_name[0] === 'Action-Server') {
      if (this.props.outputs.filter(output => output.output_type_id === this.props.io_type_id && output.name === this.state.name).length > 0) {
        confirm = window.confirm("A " + this.props.io_type_name + " with the same name already exists. Continue?")
      }
    }
    if (confirm) {
      fetchAddNew(this.context.device, this.props.item_type + 's', attributes_db)
      .then(item => {
        if (Array.isArray(item)) {
          console.log("Return")
          var io_id = item[0][this.props.item_type + '_id']
          this.addIOtoComponent(io_id, this.props.component_id)
          this.addAttributes(io_id, item[0])
          if (this.props.item_type === 'input') {
            this.checkForConnections(io_id, 'output')
          } else if (this.props.item_type === 'output') {
            this.checkForConnections(io_id, 'input')
          }
        } else {
          console.log('failure')
        }
      })
      .catch(err => console.log(err))
    }
  }

  addAttributes(io_id, new_io_item) {
    console.log('add attributes')
    const attributes = this.state.attributes

    var lastIndex = Object.entries(attributes).length
    console.log(lastIndex)
    console.log(attributes)
    if (Object.entries(attributes).length > 0) {
      Object.entries(attributes).map((attribute, index) => {
        var last = false
        if (index + 1 == lastIndex) {
          last = true
        }
        var attribute_value = {}
        attribute_value.attribute_type_id = Number(attribute[0])  //attribute_type_id
        attribute_value.content = attribute[1]                    //content

        fetchAddNew(this.context.device, 'attributes', attribute_value)
        .then(item => {
          if (Array.isArray(item)) {
            console.log(item[0])
            this.addAttributeToIO(io_id, item[0].attribute_id, last, new_io_item)
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
      })
    } else {
      this.props.addItemToState(new_io_item)
      this.props.toggle()
    }

  }

  addAttributeToIO(io_id, attribute_id, last, new_io_item) {
    console.log('addAttrbiuteToIO')
    var table_name = this.props.item_type + 's_attribute'
    fetchAddTo(this.context.device, io_id, this.props.item_type, attribute_id, 'attribute', table_name)
    .then(item => {
      if (Array.isArray(item)) {
        console.log(item[0])
        if (last) {
          this.props.addItemToState(new_io_item)
          this.props.toggle()
        }
      } else {
        console.log('failure')
      }
    })
    .catch(err => console.log(err))
  }

  addIOtoComponent(io_id, component_id) {
    console.log('addIOtoComponent')
    var table_name = 'components_' + this.props.item_type
    fetchAddTo(this.context.device, component_id, 'component', io_id, this.props.item_type, table_name)
    .then(item => {
      if (Array.isArray(item)) {
        console.log(item[0])
      } else {
        console.log('failure')
      }
    })
    .catch(err => console.log(err))
  }

  // function to check if there are suitable IOs with the same Topic-Name and connect them automatically to the added IO
  checkForConnections(io_id, other_type) {
    let connections = []
    this.props.possible_connections.forEach(item => {
      if (item.attribute_type_name === 'Topic-Name' && item.content != '' && item.content === this.state.attributes[item.attribute_type_id]) {
        connections.push(item[other_type + '_id'])
      }
    })
    if (connections.length > 0) {
      if (window.confirm("Do you want to connect this " + this.props.item_type + " with all " + other_type + "s with the same Topic-Name?")) {
        connections.map(other_id => {
          fetchAddTo(this.context.device, io_id, this.props.item_type, other_id, other_type, 'inputs_output')
          .then(item => {
            if (!Array.isArray(item)) {
              console.log('failure')
            }
          })
          .catch(err => console.log(err))
        })
      }
    }
  }

  componentDidMount() {
    const current_io_type_attributes = 
      this.props.io_type_attributes.filter(item => 
        item[this.props.item_type + '_type_id'] == this.props.io_type_id 
      )
    var init_attributes = {}
    current_io_type_attributes.map(entry => {
      init_attributes[entry.attribute_type_id] = ''
    })
    console.log(init_attributes)
    this.setState({ attributes: init_attributes })
    console.log(this.props.component_id)
  }

  render() {
    const name_form =
      <FormGroup key={'name'}>
        <Label for={'name'}>Name</Label>
        <Input 
          type="text" 
          name={'name'} 
          id={'name'} 
          onChange={this.onChangeName} 
          value={this.state.name || ''} 
          autoFocus={true}
        />
      </FormGroup>

    const current_io_type_attributes = this.props.io_type_attributes.filter(item => item[this.props.item_type + '_type_id'] = this.props.io_type_id)
    const attributes_form = current_io_type_attributes.map((attribute, index) => {
      if (attribute.attribute_type === 'text' || attribute.attribute_type === 'textarea') {
        return (
          <FormGroup key={attribute.attribute_type_id}>
            <Label for={attribute.attribute_type_id}>{attribute.attribute_type_name}</Label>
            <Input 
              type="text" 
              name={attribute.attribute_type_id} 
              id={attribute.attribute_type_id} 
              onChange={this.onChange} 
              value={this.state.attributes.attribute_type_id} 
            />
          </FormGroup>
          )
      } else if (attribute.attribute_type == 'dropdown'){
        var attribute_type_content = this.props.attribute_types_content.filter(attribute_types_content => attribute_types_content.attribute_type_id == attribute.attribute_type_id)
        var options = attribute_type_content.map(attribute_type_content => {
          return(
            <option key={attribute_type_content.attribute_types_content_id} value={attribute_type_content.content}>{attribute_type_content.content}</option>
          )
        })
        return (
          <FormGroup key={attribute.attribute_type_id}>
            <Label for={attribute.attribute_type_id}>{attribute.attribute_type_name}</Label>
            <Input type="select" 
                name={attribute.attribute_type_id} 
                id={attribute.attribute_type_id}  
                onChange={this.onChange}>
                <option key={"select"} value={"select"}>Auswählen</option>
                {options}
            </Input>
          </FormGroup> 
        )
      } else if (attribute.attribute_type == 'datastream'){
        var attribute_type_content = this.props.attribute_types_content.filter(attribute_types_content => attribute_types_content.attribute_type_id == attribute.attribute_type_id)
        var datastream_unit = attribute_type_content.map(attribute_type_content => {
          return(
            attribute_type_content.content
          )
        })
        return (
          <FormGroup key={attribute.attribute_type_id}>
            <Label for={attribute.attribute_type_id}>{attribute.attribute_type_name} ({datastream_unit})</Label>
            <Input 
              type="text" 
              name={attribute.attribute_type_id} 
              id={attribute.attribute_type_id} 
              onChange={this.onChange} 
              value={this.state.attributes.attribute_type_id} 
            />
          </FormGroup>
        )
      }
    })

    return (
      <Form onSubmit={this.submitFormAdd}>
        <div>
          {name_form}
        </div>
        <div>
          Attribute
        {attributes_form}
        </div>
        <Button disabled={!this.state.name}>Hinzufügen</Button>
      </Form>
    )
  }
}
export default AddIOForm