import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap"
import api_url from "../../../helpers/config.js"
import Rev_ConnectionTable from 'components/Extensions/Tables/Revisions/Rev_ConnectionTable.js'
import { fetchGetDataWithDeleted, fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Rev_Input_types_Card extends Component {
  static contextType = DeviceContext
  
  state = {
    views_input_type: [],
    input_types: []
  }

  async getItems() {
    try {
      const viewsInputTypeResponse = await fetchGetDataWithDeleted(this.context.device, 'views_input_type');
      if (viewsInputTypeResponse.dataExists != 'false') {
        this.setState({ views_input_type: viewsInputTypeResponse });
      }
  
      const inputTypesResponse = await fetchGetData(this.context.device, 'input_types');
      if (inputTypesResponse.dataExists != 'false') {
        this.setState({ input_types: inputTypesResponse });
      }
    } catch (err) {
      console.error(err);
    }
  }

  componentDidMount() {
    this.getItems()
  }

  refresh = () => {
    this.getItems()
  }

  render() {
    if (this.state.views_input_type.dataExists != 'false') {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Input Types Revisions</CardTitle>
          </CardHeader>
          <CardBody>
            <Rev_ConnectionTable
              item_type={'view'}
              child_items={this.state.input_types}
              child_type={'input_type'}
              parent_child_items={this.state.views_input_type.dataExists != 'false' ? this.state.views_input_type.filter(item => item.view_id == this.props.view_id) : this.state.views_input_type}
              columns={['Name']}
              columns_DB={['name']}
              refresh={this.refresh}
            />
          </CardBody>
        </Card>
      )
    } else {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Input Types Revisions</CardTitle>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      )
    }
  }
}
export default Rev_Input_types_Card