import React, { Component } from 'react'
import { Row, Col, CardBody, Card, CardHeader, CardTitle } from 'reactstrap'
import api_url from "../../../helpers/config.js"
import Rev_DataTable from 'components/Extensions/Tables/Revisions/Rev_DataTable.js'
import { fetchGetDataWithDeleted } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Rev_Header_Card extends Component {
  static contextType = DeviceContext

  state = {
    items: [],
    rev_items: []
  }

  getItems() {
    fetchGetDataWithDeleted(this.context.device, 'views')
    .then(items => {
      if (items.dataExists != 'false') {
        this.setState({ items })
      }
    })
    .catch(err => console.log(err))

    fetchGetDataWithDeleted(this.context.device, 'views_revisions')
    .then(rev_items => {
      if (rev_items.dataExists != 'false') {
        this.setState({ rev_items })
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.getItems()
  }

  refresh = () => {
    this.getItems()
  }

  render() {
    // get view name and description
    if (this.state.items.dataExists != 'false') {
      console.log(this.state.items)
      console.log(this.props.view_id)
      const current_view = this.state.items.filter(item => item.view_id == this.props.view_id)
      const view_name = current_view.map(view => {
        return (
          view.name
        )
      })

      return (
        <Card>
          <CardHeader>
            <Row>
              <Col className="text-left" sm="6">
                <CardTitle tag="h2">{view_name}</CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <h4>Revisions</h4>
                <Rev_DataTable
                  items={this.state.items.filter(item => item.view_id == this.props.view_id)}
                  rev_items={this.state.rev_items.dataExists != 'false' ? this.state.rev_items.filter(rev_item => rev_item.view_id == this.props.view_id) : this.state.rev_items}
                  item_type='view'
                  columns={['Name', 'Beschreibung']}
                  columns_DB={['name', 'description']}
                  revision_columns={['name', 'description']}
                  refresh={this.refresh}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      )
    } else {
      return (
        <Card>
          <CardHeader>
            <Row>
              <Col className="text-left" sm="6">
                <CardTitle tag="h2">Undefinierte Sicht</CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      )
    }
  }
}

export default Rev_Header_Card