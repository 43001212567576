/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react"

//  Submodules
import Rev_Views_Card from "./Rev_Views_Card"
import Rev_Input_types_Card from "./Rev_Input_types_Card"
import Rev_Output_types_Card from "./Rev_Output_types_Card"
import Rev_Attribute_types_Card from "./Rev_Attribute_types_Card"

import Rev_Modules_Card from "./Rev_Modules_Card"



class Rev_View_Management extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          <Rev_Views_Card />
          <Rev_Modules_Card />
          <Rev_Input_types_Card />
          <Rev_Output_types_Card />
          <Rev_Attribute_types_Card />
        </div>
      </>
    )
  }
}

export default Rev_View_Management
