/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react"
import { fetchGetData, fetchRemove, fetchRemoveFrom } from "helpers/api"

// reactstrap components
import Header_Card from "./Header_Card"
import IO_type_Head_Card from "./IO_types_Head_Card"
import Export_Card from "./Export_Card.js"
import Parameters_Card from "./Parameters_Card"
import { DeviceContext } from 'context/DeviceContext'

class ComponentP extends React.Component {
  static contextType = DeviceContext

  state = {
    attribute_types_content: [],
    components: [],
    components_input_sum: [],
    components_internal_sum: [],
    components_output_sum: [],
    input_types_attribute_type_sum: [],
    inputs: [],
    inputs_attribute_sum: [],
    inputs_output_sum: [],
    internal_connections_sum_with_types: [],
    internal_types_attribute_type_sum: [],
    internals: [],
    internals_attribute_sum: [],
    output_types_attribute_type_sum: [],
    outputs: [],
    outputs_attribute_sum: [],
    views_component_sum: [],
    views_input_type_sum: [],
    views_internal_type_sum: [],
    views_output_type_sum: [],
    refresh: true
  }


  async getItems() {
    const requestList = [
      { table: 'attribute_types_content', device: this.context.device },
      { table: 'components', device: {} },
      { table: 'components_input_sum', device: this.context.device },
      { table: 'components_internal_sum', device: this.context.device },
      { table: 'components_output_sum', device: this.context.device },
      { table: 'input_types_attribute_type_sum', device: this.context.device },
      { table: 'inputs', device: this.context.device },
      { table: 'inputs_attribute_sum', device: this.context.device },
      { table: 'inputs_output_sum', device: this.context.device },
      { table: 'internal_connections_sum_with_types', device: this.context.device },
      { table: 'internal_types_attribute_type_sum', device: this.context.device },
      { table: 'internals', device: this.context.device },
      { table: 'internals_attribute_sum', device: this.context.device },
      { table: 'output_types_attribute_type_sum', device: this.context.device },
      { table: 'outputs', device: this.context.device },
      { table: 'outputs_attribute_sum', device: this.context.device },
      { table: 'views_component_sum', device: this.context.device },
      { table: 'views_input_type_sum', device: this.context.device },
      { table: 'views_internal_type_sum', device: this.context.device },
      { table: 'views_output_type_sum', device: this.context.device }
    ]

    try {
      let data = await Promise.all(requestList.map(request => fetchGetData(request.device, request.table)))
  
      data = data.map(item => {
        if (item.dataExists === 'false') return []
        else return item
      })

      requestList.forEach((request, index) => {
        this.setState({ [request.table]: data[index] })
      })
    } catch (err) {
      console.log(err)
    }
  }

  componentDidMount() {
    this.getItems()
  }

  // adds an input/output/internal to the state
  addIO = async (item_type, new_item) => {
    let items = this.state[item_type + 's']
    items.push(new_item)

    let io_attribute_sum = await fetchGetData(this.context.device, [item_type + 's_attribute_sum'])
    if (io_attribute_sum.dataExists === 'false') io_attribute_sum = this.state[item_type + 's_attribute_sum']

    let components_io_sum = await fetchGetData(this.context.device, 'components_' + item_type + '_sum')
    if (components_io_sum.dataExists === 'false') components_io_sum = this.state['components_' + item_type + '_sum']

    this.setState({ [item_type + 's']: items, [item_type + 's_attribute_sum']: io_attribute_sum, ['components_' + item_type + '_sum']: components_io_sum })
  }

  addAttribute = (item_type, io_item, new_attribute) => {
    new_attribute[item_type + '_id'] = io_item[item_type + '_id']
    new_attribute[item_type + '_name'] = io_item['name']
    new_attribute[item_type + '_type_id'] = io_item[item_type + '_type_id']
    new_attribute[item_type + '_component_id'] = this.props.id
    let io_attribute_sum = this.state[item_type + 's_attribute_sum']
    io_attribute_sum.push(new_attribute)
    this.setState({ [item_type + 's_attribute_sum']: io_attribute_sum })
  }

  // adds an internal connection to the state
  addInternalConnection = async (new_item) => {
    let internal_connections_sum_with_types = await fetchGetData(this.context.device, 'internal_connections_sum_with_types')
    this.setState({ internal_connections_sum_with_types })
  }

  // adds a connection to the state
  addConnection = async (new_item) => {
    let inputs_output_sum = await fetchGetData(this.context.device, 'inputs_output_sum')
    this.setState({ inputs_output_sum })
  }

  // removes an input/internal/output from the DB and the state
  deleteIO = (item_type, id) => {
    let confirm_delete = window.confirm('Delete ' + item_type + '?')
    if (confirm_delete) {
      fetchRemove(item_type + 's', id)
      .catch(err => console.log(err))

      let components_io_id = this.state['components_' + item_type + '_sum'].find(item => item[item_type + '_id'] === id)['components_' + item_type + '_id']
      fetchRemoveFrom('components_' + item_type, components_io_id)
      .catch(err => console.log(err))

      let items = this.state[item_type + 's']
      items = items.filter(item => item[item_type + '_id'] !== id)

      let components_io_sum = this.state['components_' + item_type + '_sum'].filter(item => item[item_type + '_id'] !== id)

      let internal_connections_sum_with_types = this.state.internal_connections_sum_with_types.filter(item => 
        item['origin_' + item_type + '_id'] !== id && item['destination_' + item_type + '_id'] !== id)

      this.setState({ [item_type + 's']: items, ['components_' + item_type + '_sum']: components_io_sum, internal_connections_sum_with_types })
    }
  }

  deleteAttribute = (item_type, id) => {
    let io_attribute_sum = this.state[item_type + 's_attribute_sum'].filter(item => item['attribute_id'] !== id)
    this.setState({ [item_type + 's_attribute_sum']: io_attribute_sum })
  }

  // removes an internal connection from the state
  deleteInternalConnection = (id) => {
    let items = this.state.internal_connections_sum_with_types.filter(item => item.internal_connections_id !== id)
    this.setState({ internal_connections_sum_with_types: items })
  }

  // removes a connection from the state
  deleteConnection = (id) => {
    let items = this.state.inputs_output_sum.filter(item => item.inputs_output_id !== id)
    this.setState({ inputs_output_sum: items })
  }

  // updates an input/output/internal in the state
  updateIO = (item_type, updated_item) => {
    const item_index = this.state[item_type + 's'].findIndex(item => item[item_type + '_id'] === updated_item[item_type + '_id'])
    const items = [...this.state[item_type + 's'].slice(0, item_index),  updated_item, ...this.state[item_type + 's'].slice(item_index + 1)]

    let components_io_sum = this.state['components_' + item_type + '_sum']
    const components_io_index = components_io_sum.findIndex(item => item[item_type + '_id'] === updated_item[item_type + '_id'])
    components_io_sum[components_io_index][item_type + '_name'] = updated_item['name']

    this.setState({ [item_type + 's']: items, ['components_' + item_type + '_sum']: components_io_sum })
  }

  // updates an attribute in the state
  updateAttributes = (item_type, updated_item) => {
    let io_attribute_sum = this.state[item_type + 's_attribute_sum']
    const io_attribute_index = io_attribute_sum.findIndex(item => item.attribute_id === updated_item.attribute_id)
    io_attribute_sum[io_attribute_index].content = updated_item.content
    this.setState({ [item_type + 's_attribute_sum']: io_attribute_sum })
  }

  render() {
    // get the view_id the component is assigned to
    var current_view = this.state.views_component_sum.filter(item => item.component_id == this.props.id)
    // component_view_id is the view_id the component is assigned to
    var component_view_id = ''
    if (current_view.length > 0){
      component_view_id = current_view[0].view_id
    } else {
      component_view_id = 0
    }
    // renders a grid type view, width is 12
    return (
      <>
        <div className="content">
          <Header_Card
            component_id={this.props.id}
            components={this.state.components}
            user_type_id={this.props.user_type_id}
            view={current_view[0]}
          />
          <Export_Card
            addIO={this.addIO}
            addInternalConnection={this.addInternalConnection}
            deleteIO={this.deleteIO}
            deleteInternalConnection={this.deleteInternalConnection}
            updateIO={this.updateIO}
            attribute_types_content={this.state.attribute_types_content}
            component_id={this.props.id}
            components={this.state.components}
            components_input_sum={this.state.components_input_sum}
            components_internal_sum={this.state.components_internal_sum}
            components_output_sum={this.state.components_output_sum}
            input_types_attribute_type_sum={this.state.input_types_attribute_type_sum}
            internal_connections_sum_with_types={this.state.internal_connections_sum_with_types}
            internal_types_attribute_type_sum={this.state.internal_types_attribute_type_sum}
            output_types_attribute_type_sum={this.state.output_types_attribute_type_sum}
            outputs_attribute_sum={this.state.outputs_attribute_sum}
            user_type_id={this.props.user_type_id}
            view_id={component_view_id}
            views_input_type_sum={this.state.views_input_type_sum.filter(item => item.view_id === component_view_id)}
            views_internal_type_sum={this.state.views_internal_type_sum.filter(item => item.view_id === component_view_id)}
            views_output_type_sum={this.state.views_output_type_sum.filter(item => item.view_id === component_view_id)}
          />
          <Parameters_Card
            component_id={this.props.id}
            components={this.state.components}
            user_type_id={this.props.user_type_id}
          />
          {/*The io cards are split into two rendering parts, a head and a sub part. the head also renders the sub part*/}
          {['internal', 'input', 'output'].map((item_type, index) => {
            return  <IO_type_Head_Card
                      key={index}
                      item_type={item_type}
                      addAttribute={this.addAttribute}
                      addIO={this.addIO}
                      addInternalConnection={this.addInternalConnection}
                      addConnection={this.addConnection}
                      deleteAttribute={this.deleteAttribute}
                      deleteIO={this.deleteIO}
                      deleteInternalConnection={this.deleteInternalConnection}
                      deleteConnection={this.deleteConnection}
                      updateIO={this.updateIO}
                      updateAttributes={this.updateAttributes}
                      attribute_types_content={this.state.attribute_types_content}
                      component_id={this.props.id}
                      component_view_id={component_view_id}
                      components={this.state.components}
                      components_input_sum={this.state.components_input_sum}
                      components_internal_sum={this.state.components_internal_sum}
                      components_output_sum={this.state.components_output_sum}
                      input_types_attribute_type_sum={this.state.input_types_attribute_type_sum}
                      inputs={this.state.inputs}
                      inputs_attribute_sum={this.state.inputs_attribute_sum}
                      inputs_output_sum={this.state.inputs_output_sum}
                      internal_connections_sum_with_types={this.state.internal_connections_sum_with_types}
                      internals={this.state.internals}
                      internals_attribute_sum={this.state.internals_attribute_sum}
                      internal_types_attribute_type_sum={this.state.internal_types_attribute_type_sum}
                      output_types_attribute_type_sum={this.state.output_types_attribute_type_sum}
                      outputs={this.state.outputs}
                      outputs_attribute_sum={this.state.outputs_attribute_sum}
                      user_type_id={this.props.user_type_id}
                      views_io_type_sum={this.state['views_' + item_type + '_type_sum']}
                    />
            })}
        </div>
      </>
    )
  }
}

export default ComponentP
