import { fetchAddNew, fetchGetData } from "helpers/api"

function addTypesToView(device, view_id, ros_types) {
  // add items to the newly added view
  ros_types.map((current, index) => {
    if (index > 1 && 'entry' in current) {
      fetchAddNew(device, 'views_' + current.type, {view_id: view_id, [current.type + '_id']: current.entry[current.type + '_id']})
      .then(item => {
        if (Array.isArray(item)) {
          console.log(item[0])
        } else {
          console.log('failure')
        }
      })
      .catch(err => console.log(err))
    }
  })
}

// this function handles the creation (if not yet done) and the assignment of ros-specific types when a ros view is created
export function handleRosTypes(device, view_id, views) {
  // all default, ros-specific types:
  let ros_types = [
    {type: 'attribute_type', values: {name: 'Topic-Name', type: 'text'}},
    {type: 'attribute_type', values: {name: 'Datatype', type: 'text'}},
    {type: 'attribute_type', values: {name: 'Frequency', type: 'text'}},
    {type: 'input_type', values: {name: 'Subscriber'}},
    {type: 'input_type', values: {name: 'Service-Client'}},
    {type: 'input_type', values: {name: 'Action-Client'}},
    {type: 'output_type', values: {name: 'Publisher'}},
    {type: 'output_type', values: {name: 'Service-Server'}},
    {type: 'output_type', values: {name: 'Action-Server'}},
    {type: 'internal_type', values: {name: 'Function'}},
    {type: 'internal_type', values: {name: 'Timer'}},
    {type: 'internal_type', values: {name: 'Buffer'}}
  ]
  let first_view = true
  if (views.dataExists !== 'false') {
    views.map(view => {
      if (view.ros2_view) {
        first_view = false
      }
    })
  }
  if (first_view) {
    // if this is the first ever created ros-view create db-entries for all ros-specific types
    let ros_types_attribute_type = []
    let ros_types_counter = ros_types.length
    ros_types.forEach((current, index, array) => {
      fetchAddNew(device, current.type + 's', current.values)
      .then(items => {
        if (Array.isArray(items)) {
          array[index].entry = items[0]
          // save ids for connection of types and attributes
          if (current.values.name === 'Subscriber') {
            ros_types_attribute_type.push({type: 'input_types_attribute_type', values: {input_type_id: items[0].input_type_id, attribute_type_id: array[0].entry.attribute_type_id}})
            ros_types_attribute_type.push({type: 'input_types_attribute_type', values: {input_type_id: items[0].input_type_id, attribute_type_id: array[1].entry.attribute_type_id}})
          } else if (current.values.name === 'Publisher') {
            ros_types_attribute_type.push({type: 'output_types_attribute_type', values: {output_type_id: items[0].output_type_id, attribute_type_id: array[0].entry.attribute_type_id}})
            ros_types_attribute_type.push({type: 'output_types_attribute_type', values: {output_type_id: items[0].output_type_id, attribute_type_id: array[1].entry.attribute_type_id}})
          } else if (current.values.name === 'Timer') {
            ros_types_attribute_type.push({type: 'internal_types_attribute_type', values: {internal_type_id: items[0].internal_type_id, attribute_type_id: array[2].entry.attribute_type_id}})
          }
          ros_types_counter--
          // as soon as the forEach is done continue
          if (ros_types_counter === 0) {
            // add all connections of form x_types_attribute_type
            let ros_types_attribute_type_counter = ros_types_attribute_type.length
            ros_types_attribute_type.map(current => {
              fetchAddNew(device, current.type, current.values)
              .then(item => {
                if (Array.isArray(item)) {
                  console.log(item[0])
                  ros_types_attribute_type_counter--
                  // as soon as the map is done continue
                  if (ros_types_attribute_type_counter === 0) {
                    addTypesToView(device, view_id, ros_types)
                  }
                } else {
                  console.log('failure')
                }
              })
              .catch(err => console.log(err))
            })
          }
        } else {
          console.log('failure')
        }
      })
      .catch(err => console.log(err))
    })
  } else {
    // if it is not the first ros-view fetch all types from the db
    let all_types = {attribute_types: [], input_types: [], output_types: [], internal_types: []}
    let all_types_counter = Object.keys(all_types).length
    Object.keys(all_types).forEach(key => {
      fetchGetData(device, key)
      .then(items => {
        if (items.dataExists != 'false') {
          all_types[key] = items
        }
        all_types_counter--
        // as soon as the forEach is done continue
        if (all_types_counter === 0) {
          // for each ros-type search for the db-entry to get the id
          ros_types.forEach((current, index, array) => {
            all_types[current.type +'s'].map(type_entry => {
              if(type_entry.name === current.values.name) {
                array[index].entry = type_entry
              }
            })
          })
          addTypesToView(device, view_id, ros_types)
        }
      })
      .catch(err => console.log(err))
    })
  }
}