
import React, { createContext, useDebugValue, useState } from 'react';

const defaultDevice = {name: 'Kein Gerät ausgewählt'}

export const DeviceContext = createContext(defaultDevice);

export const DeviceContextProvider = ({ children }) => {
  const [device, setDevice] = useState(defaultDevice);

  const updateDevice = (newDevice) => {
    setDevice(newDevice);
  };

  return (
    <DeviceContext.Provider value={{ device, updateDevice }}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDeviceContext = () => {
    const context = React.useContext(DeviceContext);
    useDebugValue(context.device ?? "no device set");
    if (context === null) {
        throw new Error(
        'useDeviceContext must be used within a DeviceContextProvider'
        );
    }
    return context;
}