import React from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import api_url from "../../../helpers/config.js"
import { DeviceContext } from '../../../context/DeviceContext.js'
import { fetchAddNew } from 'helpers/api.js'

class AddFormUser extends React.Component {
  static contextType = DeviceContext

  state = {
	  attributes: {},
    error: ""
  }
  
	onChange = e => {
	  //  only update the changed entry in attributes value
	  var new_attributes = {}
	  const old_attributes = this.state.attributes
	  Object.entries(old_attributes).map(entry => {
		  if (entry[0] == e.target.name) {
			  if (entry[0] === 'user_type_id') {
          new_attributes[e.target.name] = Number(e.target.value)
        } else {
          new_attributes[e.target.name] = e.target.value
        }
		  } else {
			  new_attributes[entry[0]] = entry[1]
		  }
	  })
	  this.setState({ attributes: new_attributes })
	}

  submitFormAdd = e => {
    e.preventDefault()
    fetchAddNew(this.context.device, this.props.item_type + 's', this.state.attributes)
    .then(item => {
      if (Array.isArray(item)) {
        console.log('Added:')
        console.log(item[0])
        this.props.addItemToState(item[0])
        this.props.toggle()
      } else {
        console.log('failure')
        if (item.error_code === 'P2002') {
          this.setState({ error: "Ein Account mit dieser Email existiert bereits" })
        } else if (item.error === "Illegal email domain") {
          this.setState({ error: "Ungültige Email Domain" })
        }
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    // if item exists, populate the state with proper data
    var init_attributes = {}
    this.props.columns_DB.map(entry => {
      if (entry === 'user_type_id') {
        init_attributes[entry] = 0
      } else {
        init_attributes[entry] = ''
      }
    })
    this.setState({ attributes: init_attributes })
  }

  render() {
    // use columns to write the headlines
    // use database labels for name and id of Input Form
    // autoFocus on first input field
    const form = this.props.columns.map((column, index) => {
      const form_db = this.props.columns_DB[index]
      if (index == 0) {
        return (
          <FormGroup key={index}>
            <Label for={form_db}>{column}</Label>
            <Input type="text" name={form_db} id={form_db} onChange={this.onChange} value={this.state.attributes[form_db] || ''} autoFocus={true}/>
          </FormGroup>
        )
      } else if (form_db === 'password') {
        return (
          <FormGroup key={index}>
            <Label for={form_db}>{column}</Label>
            <Input type="password" name={form_db} id={form_db} onChange={this.onChange} value={this.state.attributes[form_db] || ''} />
          </FormGroup>
        )
      } else if (form_db === 'user_type_id') {
        return (
          <FormGroup key={index}>
            <Label for={form_db}>{column}</Label>
            <Input
              type="select"
              name={form_db}
              id={form_db}
              onChange={this.onChange}
              value={this.state.attributes[form_db]}>
              <option value='0'>Gast</option>
              <option value='1'>Entwickler</option>
              <option value='2'>Administrator</option>
            </Input>
          </FormGroup>
        )
      } else {
        return (
          <FormGroup key={index}>
            <Label for={form_db}>{column}</Label>
            <Input type="text" name={form_db} id={form_db} onChange={this.onChange} value={this.state.attributes[form_db] || ''} />
          </FormGroup>
        )
      }
    })

    return (
      <Form onSubmit={this.submitFormAdd}>
        {form}
        <div style={{color: 'red'}}> {this.state.error} </div>
        <Button disabled={!(this.state.attributes.name && this.state.attributes.email && this.state.attributes.password)}>Hinzufügen</Button>
      </Form>
    )
  }
}

export default AddFormUser