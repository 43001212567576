import React, { Component } from 'react'
import { Row, Col, CardBody, Card, CardHeader, CardTitle } from 'reactstrap'
import api_url from "../../../helpers/config.js"
import Rev_DataTable from 'components/Extensions/Tables/Revisions/Rev_DataTable.js'
import { fetchGetData, fetchGetDataWithDeleted } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Rev_Header_Card extends Component {
  static contextType = DeviceContext

  state = {
    component_id: 0,
    items: [],
    rev_items: [],
    views: []
  }

  getItems() {
    fetchGetDataWithDeleted(this.context.device, 'components')
    .then(items => {
      if (items.dataExists != 'false') {
        this.setState({ items })
      }
    })
    .catch(err => console.log(err))

    fetchGetDataWithDeleted(this.context.device, 'components_revisions')
    .then(rev_items => {
      if (rev_items.dataExists != 'false') {
        this.setState({ rev_items })
      }
    })
    .catch(err => console.log(err))

    fetchGetDataWithDeleted(this.context.device, 'views')
    .then(views => {
      if (views.dataExists != 'false') {
        this.setState({ views })
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.setState({ component_id: this.props.component_id })
    this.getItems()
  }

  refresh = () => {
    this.getItems()
  }

  render() {
    //  defaults if no entries in DB or no connections set
    var component_name = "Diese Komponente existiert nicht"
    let component_view_name = "Diese Komponente ist keiner Sicht zugehörig"

    // get component name and description
    if (this.state.items.dataExists != 'false') {
      const current_component = this.state.items.filter(item => item.component_id == this.state.component_id)
      component_name = current_component.map(component => {
        return (
          component.name
        )
      })

      // get view name component is assigned to
      if (this.props.component_view_id != 0) {
        component_view_name = this.state.views.map(view => {
          if (view.view_id == this.props.component_view_id) {
            return (
              view.name
            )
          }
        })
      }

      return (
        <Card>
          <CardHeader>
            <Row>
              <Col className="text-left" sm="6">
                <h5 className="card-category">{component_view_name}</h5>
                <CardTitle tag="h2">{component_name}</CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <h4>Revisions</h4>
                <Rev_DataTable
                  items={this.state.items.filter(component => component.component_id == this.state.component_id)}
                  rev_items={this.state.rev_items.dataExists != 'false' ? this.state.rev_items.filter(component => component.component_id == this.state.component_id) : this.state.rev_items}
                  item_type='component'
                  columns={['Name', 'Beschreibung']}
                  columns_DB={['name', 'description']}
                  revision_columns={['name', 'description']}
                  refresh={this.refresh}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      )
    } else {
      // if there are no revision dont render table
      return (
        <Card>
          <CardHeader>
            <Row>
              <Col className="text-left" sm="6">
                <h5 className="card-category">{component_view_name}</h5>
                <CardTitle tag="h2">{component_name}</CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      )
    }
  }
}

export default Rev_Header_Card