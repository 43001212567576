import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle} from "reactstrap"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import { fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Components_Card extends Component {
  static contextType = DeviceContext

  state = {
    components_user: [],
    components: []
  }

  getItems() {
    fetchGetData(this.context.device, 'components_user_sum')
    .then(components_user => {
      if (components_user.dataExists !== 'false') {
        this.setState({ components_user })
      }
    })
    .catch(err => console.log(err))
  
    fetchGetData({}, 'components')
    .then(components => {
      if (components.dataExists !== 'false') {
        this.setState({ components })
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    let components_user = this.state.components_user.filter(component_user => component_user.user_id === this.props.user_id)
    let items = []
    components_user.forEach(item => {
      const component = this.state.components.find(comp => comp.component_id === item.component_id)
      if (component) {
        item['device_names'] = component.devices.map(device => device.name).join(', ')
        items.push(item)
      }
    })
    return (
        <Card>
        <CardHeader>
          <div style={{display : 'inline-block'}}>
            <CardTitle tag="h4">Meine Komponenten</CardTitle>
          </div>
        </CardHeader>
        <CardBody>
            <DataTableDeleteAndEdit
              items={items}
              item_type={'components_user'}
              columns={['Name', 'Sicht', 'Geräte']}
              columns_DB={['component_name', 'view_name', 'device_names']}
              links={{'component_name': {'id': 'component_id', 'type': 'component'}, 'view_name': {'id': 'view_id', 'type': 'view'}}}
            />
        </CardBody>
      </Card>
    )
  }
}
export default Components_Card