import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import DataTableDeleteAndEdit from '../Tables/DataTableDeleteAndEdit.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faX } from "@fortawesome/free-solid-svg-icons"
import { DeviceContext } from 'context/DeviceContext'
import { fetchGetData } from 'helpers/api.js'

class Owner_Modal extends Component {
  static contextType = DeviceContext

  state = {
    open: false,
    owners: [],
    users: []
  }
    
  getItems() {
    fetchGetData(this.context.device, this.props.parent_type + 's_user')
    .then(owners => {
      if (owners.dataExists != 'false') {
        this.setState({ owners })
      }
      })
    .catch(err => console.log(err))
    
    fetchGetData(this.context.device, 'users')
    .then(users => {
      if (users.dataExists != 'false') {
        this.setState({ users })
      }
      })
    .catch(err => console.log(err))
  }   
    
  addItemToState = (item) => {
    let owners = this.state.owners
    owners.unshift(item)
    this.setState({ owners })
  }
  
  deleteItemFromState = (id) => {
    const updatedItems = this.state.owners.filter(item => item[this.props.parent_type + 's_user_id'] !== id)
    this.setState({ owners: updatedItems })
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    const close_btn = <button className='btn-minimal' onClick={() => this.setState({ open: false })}><FontAwesomeIcon icon={faX} /></button>
    const owner_btn = <button onClick={() => this.setState({ open: true })} className='btn-minimal btn-owner' data-toggle='tooltip' title="Verantwortliche Nutzer" hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faUser} /></button>
    let owners = this.state.owners.filter(owner => owner[this.props.parent_type +  '_id'] == this.props.parent_id)
    let owner_ids = owners.map(owner => owner['user_id'])
    let users = this.state.users.map(user => {
      let new_user = user
      new_user['full_name'] = user['name'] + ', ' + user['first_name']
      let owner = owners.find(owner => owner['user_id'] === user['user_id'])
      new_user[this.props.parent_type + 's_user_id'] = owner ? owner[this.props.parent_type + 's_user_id'] : ''
      return new_user
    })
    users = users.sort((user1, user2) => {
      return user1['full_name'].toString().localeCompare(user2['full_name'].toString(), undefined, {sensitivity: 'base'})
    })
    return (
      <div style={{ display: 'inline-block' }}>
        {owner_btn}
        <Modal show={this.state.open} onHide={() => this.setState({ open: false})} className={this.props.className} size='lg'>
          <Modal.Header style={{padding: 30}}>{close_btn}</Modal.Header>
          <Modal.Body>
            <DataTableDeleteAndEdit
              title={'Verantwortliche Nutzer'}
              items={users.filter(user => owner_ids.includes(user['user_id']))}
              deleteChildItemFromState={this.deleteItemFromState}
              addItemToState={this.addItemToState}
              columns={['Name']}
              columns_DB={['full_name']}
              links={{'full_name': {'id': 'user_id', 'type': 'user'}}}
              select={{'full_name': {items: users.filter(user => !owner_ids.includes(user['user_id'])), col_width: 1, columns: ['full_name']}}}
              item_type={this.props.parent_type + 's_user'}
              parent_type={this.props.parent_type}
              parent_id={this.props.parent_id}
              child_type='user'
              withDelete={true}
              deleteFrom={true}
              addTo={true}
            />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default Owner_Modal