import React, { Component } from 'react'
import { Input, CardBody, Card, CardHeader, CardTitle} from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen, faCheck, faX } from "@fortawesome/free-solid-svg-icons"
import { fetchGetData, fetchEdit } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Header_Card extends Component {
  static contextType = DeviceContext

  state = {
    item: {},
    edit: null
  }

  getItems() {
    fetchGetData(this.context.device, "views")
    .then(items => {
      if (items.dataExists !== 'false') {
        this.setState({ item: items.find(item => item.view_id === this.props.view_id) })
      }
      })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.getItems()
  }

  onChange = (e) => {
    const { name, value } = e.target
    let edit_item = this.state.edit
    edit_item[name] = value
    this.setState({ edit: edit_item })
  }

  submitChange = () => {
    if (this.state.edit['name']) {
      let edit_item = {name : this.state.edit['name'].trim(), description: this.state.edit['description'].trim()}
      fetchEdit('views', undefined, this.state.item['view_id'], edit_item)
      .then(item => {
        this.setState({ edit: null })
        this.setState({ item: item[0] })
      })
      .catch(err => console.log(err))
    }
  }

  render() {
    const view = this.state.item

    let card_header
    if (this.state.edit) {
      const input_style = this.state.edit['name'] ? {} : {borderColor: 'red', boxShadow: '0 0 5px red'} // style of the input field when it is empty
      card_header = 
        <>
          <Input
            type="text"
            name={'name'}
            autoFocus={true}
            onChange={this.onChange}
            value={this.state.edit['name'] || ''}
            placeholder='Name'
            style={{...{width: Math.max(140, this.state.edit['name'].length * 17), padding: '5px', marginLeft: '10px', fontSize: '30px'}, ...input_style}}
          />
          <button onClick={() => this.submitChange()} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faCheck} /></button>
          <button onClick={() => this.setState({ edit: null })} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faX} /></button>
        </>
    } else {
      card_header =
        <>
          {view['name']}
          <button onClick={() => this.setState({ edit: { ...view } })} className='btn-minimal btn-edit' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faPen} /></button>
        </>
    }

    let card_body = <></>
    if (this.state.edit) {
      card_body =
        <CardBody>
          <h4>Beschreibung</h4>
          <Input
            type="textarea"
            name={'description'}
            onChange={this.onChange}
            value={this.state.edit['description'] || ''}
            style={{padding: '5px'}}
          />
        </CardBody>
    } else if (view.description) {
      card_body = 
        <CardBody style={{whiteSpace: "pre-line"}}>
          <h4>Beschreibung</h4>
          {view.description}
        </CardBody>
    }

    return (
      <Card>
        <CardHeader>
        <CardTitle tag="h2">
            <div className='d-flex' style={{ display: 'inline-block' }}>
              Sicht: {card_header}
            </div>
          </CardTitle>
        </CardHeader>
        {card_body}
      </Card>
    )
  }
}

export default Header_Card